import { Http } from "./core/http";
import { Toast } from "antd-mobile";

export class OrderService {

  static http: Http = new Http();

  static async createOrder(data: any) {
    const img = data.img;
    delete data.img;
    const { order_id } = await this.http.post('/order/createOrderNew', data);
    Toast.loading('上传图片中...', 0);
    await this.http.upload('/order/orderImgUpload', { order_id, img});
    return ;
  }
  static orderCustomerList(page: number, rows: number, query: any) {
    return this.http.post('/order/getList', { page, rows, ...query});
  }
  static orderList(page: number, rows: number, query: any) {
    return this.http.post('/order/getList', { page, rows, ...query});
  }

  static downloadOrderList(start_time: string, end_time: string) {
    return this.http.get('/order/getList', { end_time, start_time});
  }

  static detail(order_id: string) {
    return this.http.post('/order/detail', { order_id });
  }

  static revoke(order_id: number) {
    return this.http.post('/order/revokeNew', { order_id});
  }

  static reject(order_id: number, reason?: string) {
    return this.http.post('/order/rejectNew', { order_id, reason});
  }

  static makeOutOrder(order_id: string) {
    return this.http.post('/order/accept', { order_id, state: 1});
  }

  static async resubmit(data: any) {
    const img = data.img;
    const url = data.url;
    delete data.img;
    delete data.url;
    await this.http.post('/order/resubmitNew', data);
    Toast.loading('上传图片中...', 0);
    await this.http.upload('/order/orderImgUpload', { order_id: data.order_id, img, url});
    return;
  }

}
