import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './sales-statistics.scss';
import TimePicker3 from '../../components/time-picker/time-picker3'
import TimePicker2 from '../../components/time-picker/time-picker2'
import moment from 'moment';
import { LogisticsService } from '../../service/sales.servece';
import { PageOption } from '../../model';
import NoResult from '../../components/no-result/no-result';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, List, Toast } from 'antd-mobile';
import ClipboardJS from 'clipboard';
import { Navigate } from '../../tools/navigate';
import { HomeService } from '../../service/home.service';


@observer
export default class FlowInquiry extends PureComponent<any, any> {

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  @observable noResult: boolean = false;

  query: {start_time: string, end_time: string};

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      dataList1: [],
      downloadVisible: false,
      currentUrl: '',
      flag: true,
      dingdan :[],
      jine:[],
      isShow: "block",
      jine2:[],
      other: [],
      zongjine:"",
      zongdingdan:"",
      date: moment().startOf('month').format('YYYY年 MM月'),
      colorList: ['#C1232B','#B5C334','#FCCE10','#E87C25','#27727B',"#FE8463"],
    };
    this.query = {
      start_time: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      end_time: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
    };
    const clipBoard = new ClipboardJS('.flowInquiryBtn');
    clipBoard.on('success', () => {
      Toast.info('复制成功！');
      this.setState({
        downloadVisible: false
      })
    })
  }
  toDetail(item: any ,query: any) {
    Navigate.route.push(`/reportDetail/${item}/${query.start_time}/${query.end_time}`);
  }
  async getList() {
    this.setState({isShow: "block"})
    this.noResult = false;
    const data = await LogisticsService.getList(this.pageOption, this.query);

    const data1 = data.total.sort(this.compare(data.total.fee_total)).filter( (val:any,index:any) => {return index<5})
    const data2 = data.total.sort(this.compare(data.total.fee_total)).filter( (val:any,index:any) => {return index>=5})
    const datadingdan = data.total
    var other = 0
    for(let i = 0 ;i<data2.length;i++){
      other= other + Number(data2[i].fee_total)
    }
    var data3 = {"code" : "其他","fee_total" : other }
    if(data3.fee_total!==0){
       data1.push(data3)
    }
    let array1:any[] = []
    let arraydingdan: any[] = []
    if(data1!==undefined){
       data1.forEach(function(item:any){
         array1.push({value:Number(item.order_total),name: item.code + "("+item.order_total+")"},)
       })
    }
    if(datadingdan!==undefined){
      datadingdan.forEach(function(item:any){
        arraydingdan.push({value:Number(item.order_total),name: item.code + "("+item.order_total+")"},)
      })
   }
    let array2:any[] = []
    let array3: any[] = []
    if(data1!==undefined){
     data1.forEach(function(item:any){
       array2.push(item.code)
     })
    }
    if(data1!==undefined){
      data1.forEach(function(item:any){
        array3.push(item.fee_total)
      })
     }
    //  this.setState({
    //    dataList1: [...this.state.dataList1, ...data.data]
    //  });
    this.setState({
      dataList: data1
    })
    this.setState({
      dingdan: arraydingdan
    })
    this.setState({
      zongjine: data.fee_total
    })
    this.setState({
      zongdingdan: data.order_total
    })
    this.setState({
      jine: array2
    })
    this.setState({
      jine2: array3
    })
    this.setState({
      other: data3
    })
    if (this.state.jine.length === 0) {
      this.noResult = true;
      this.setState({isShow: "none"})
    }
    var echarts = require('echarts/lib/echarts');
    require('echarts/lib/chart/pie');
    require('echarts/lib/chart/bar');
    require('echarts/lib/component/tooltip');
    require('echarts/lib/component/title');

    var myChart = echarts.init(document.getElementById('main'));
    myChart.setOption({
        title: {
            text: '订单数',
            x: 'center',
            y: '20'
        },
        tooltip : {
          trigger: 'item',
          formatter: "{b} : ({d}%)"
        },
        series : [
          {
            name: '访问来源',
            type: 'pie',
            avoidLabelOverlap: true,
            hoverAnimation: true,
            radius: '55%',
            data:this.state.dingdan,
            itemStyle: {
              emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
      ]
    });
    var myChart1 = echarts.init(document.getElementById('main1'));
    myChart1.setOption({
        title: {
            text: '订单金额',
            x: 'center',
            y: '20'
        },
        tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c}"
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {type: 'value'},
        xAxis: {
          data: this.state.jine,
          axisLabel : {//坐标轴刻度标签的相关设置。
            show: false,
            interval:0,
            rotate:"45"
          },
        },
        series : [
          {
            name: '销售额',
            type: 'bar',
            data:this.state.jine2,
            itemStyle: {
              normal: {
                color: function(params:any) {
                  var colorList = [
                    '#C1232B','#B5C334','#FCCE10','#E87C25','#27727B',
                      '#FE8463','#9BCA63','#FAD860','#F3A43B','#60C0DD',
                      '#D7504B','#C6E579','#F4E001','#F0805A','#26C0C0'
                  ];
                  return colorList[params.dataIndex]
                },
                label: {
                    position: 'top',
                    formatter: '{c}'
                }
              }
            }
          }
      ]
    });
  }
  
  
  async componentWillMount() {
    const data = await HomeService.getHomeData();
      if(data.role_type===0||data.role_type===3||data.role_type===2){
      }else{
        Navigate.route.replace('/home')
      }
    this.resetSearch();
  }
 
  timeSearch = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.resetSearch();
  }
  compare(prop:any) {
    return function (a:any, b:any) {
     var value1 = a[prop]
     var value2 = b[prop]
     return value1 - value2
    }
   }
  timeSearch1 = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.resetSearch();
  }
  resetSearch() {
    this.pageOption.page = 1;
    this.setState({
      dataList1: []
    }, () => {
      this.getList();
    });
  }
  pageSearch() {
    this.pageOption.page++;
    this.getList();
  }
  changeDate() {
    let _this = this
    
    this.setState({flag: !this.state.flag},function(){
      if(_this.state.flag === false){
        let query = {timePickerVisible: false, startTimeText: moment().startOf('year').format('YYYY/MM/DD HH:mm:ss'), endTimeText: moment().endOf('year').format('YYYY/MM/DD HH:mm:ss'), startTime: moment().startOf('year').format('YYYY/MM/DD HH:mm:ss'), endTime: moment().endOf('year').format('YYYY/MM/DD HH:mm:ss')}
        _this.setState({date:moment().startOf('month').format('YYYY年MM月').split("年")[0]+"年 "+moment().startOf('month').format('YYYY年MM月').split("年")[1]})
        _this.timeSearch1(query)
      }else{
        let query = {timePickerVisible: false, startTimeText: moment().startOf('month').format('YYYY/MM/DD HH:mm:ss'), endTimeText: moment().endOf('month').format('YYYY/MM/DD HH:mm:ss'), startTime: moment().startOf('month').format('YYYY/MM/DD HH:mm:ss'), endTime: moment().endOf('month').format('YYYY/MM/DD HH:mm:ss')}
        _this.setState({date:moment().startOf('year').format('YYYY年')})
        _this.timeSearch(query)
      }
    })
    
  }
  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }
  colorBox() {
    return this.state.dataList.map((item:any,index:any) => {
      return <div style={{display: 'flex',position: 'relative'}} className="colorBoxP">
                <div style={{background:this.state.colorList[index]}} className="colorBox"></div>
                <div className="colorBoxNumber">{item.fee_total}</div>
                <div className="colorBoxText">{ item.code }</div>
                
            </div>
    })
  }
  chart() {
    return <div style={{display:this.state.isShow}}>
              <div className="medName" style={{marginBottom:'0.5rem'}}>
                <div id="main" style={{width: '9.06666rem',height: '10rem',border: '1px solid #ddd',borderRadius:'2px',background:'#fff'}}></div>
              </div>
              <div className="medName">
                <div style={{width: '100%',marginBottom: '1.2rem',border: '1px solid #ddd',borderRadius:'2px',background:'#fff'}}>
                <div className="colorBoxWrap">
                  <h2 style={{ margin: "0.2rem 0 0.2rem 0.2rem",fontSize:"0.4rem"}}>点击下方方块查看销售额</h2>
                  {this.colorBox()}
                </div>
                  <div id="main1" style={{width: "9.06666rem",height: "8rem",paddingBottom:"0.7rem"}}></div>
                </div>
              </div>
          </div>
  }
  inquiryList() {
    return this.state.dataList1.map((item: any, index: number) => {
      return <div className="item" key={index} style={{ display:this.state.isShow }}>
        <div className="medName">
          <div className="name">{item.sales_man}</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">总订单数</div>
            <div className="paramValue">{item.order_total}</div>
          </div>
          <div className="price-query">
            <div className='amount red'>总价：<br/>￥{item.fee_total}</div>
            <div className="btns">
              <button className="orderBtn" onClick={this.toDetail.bind(this, item.admin_id,this.query)}>详情</button>
            </div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="flowInquiry reportStatistics salesStatistics">
        <div className="header">
          <NavBar title="销售统计"></NavBar>
          <button className="orderBtn orderBtnTime" onClick={this.changeDate.bind(this)}>{(this.state.flag === true)?"月":"年"}</button>
          { (this.state.flag === true)?<TimePicker3 onSearch={this.timeSearch}  startTime={this.query.start_time} endTime={this.query.end_time} date={this.state.date}></TimePicker3>:
          <TimePicker2 onSearch={this.timeSearch1}  startTime={this.query.start_time} endTime={this.query.end_time}></TimePicker2>}
          <div className="statisticsBox">
            <div>
              <div className="title">总订单</div>
              <div style={{fontSize:"0.45rem",marginTop:"0.2rem"}}>{this.state.zongdingdan}</div> 
            </div>
            <div>
              <div className="title">总销售额</div>
              <div style={{fontSize:"0.45rem",marginTop:"0.2rem"}}>{this.state.zongjine}</div> 
            </div>
          </div>

        </div>
        <div className="flowInquiryList salesStatisticsList">
          { this.chart() }
          { this.inquiryList() }
          { this.noResult && <NoResult></NoResult>}
        </div>
        <Modal
          popup = {true}
          visible={this.state.downloadVisible}
          onClose={() => this.setState({downloadVisible: false})}
          animationType="slide-up"
        >
          <List renderHeader={() => <div className="flowInquiryInputUrlTitle">复制下载地址</div>} className="popup-list">
            <div className="flowInquiryInputUrl">
              <div className="tip">(请复制连接到手机自带浏览器进行下载)</div>
              <input id="flowInquiryInputUrl" readOnly defaultValue={this.state.currentUrl} />
            </div>
          </List>
        </Modal>
        {/* <div className="tab">
          <div className="left" onClick={this.tab1.bind(this)}>报表</div>
          <div className="right" onClick={this.tab2.bind(this)}>图表</div>
        </div> */}
      </div>
    )
  }
}
