import { Http } from "./core/http";
import { RepositoryQuery } from "../model";

export class RepositoryService {

  private static http: Http = new Http();

  static reposList( query: RepositoryQuery) {
    return this.http.post('/Goods/newInventoryList', query);
  }
}
