import React, { PureComponent } from 'react'
import { DatePicker } from 'antd-mobile';
import timeIcon from '../../assets/img/time.png';
import moment, { Moment } from 'moment';
import arrive from '../../assets/img/arrive.png';
// import searchIcon from '../../assets/img/search.png';
import './time-picker.scss';
export interface ITimePickerState {
  timePickerVisible: boolean;
  startTimeText: string;
  endTimeText: string;
  startTime: Date;
  endTime: Date;
  goodsName: string;
}

export default class TimePicker extends PureComponent<any, ITimePickerState> {

  openType: 'startTime' | 'endTime' = 'startTime';

  constructor(props: any) {
    super(props);
    this.state = {
      timePickerVisible: false,
      startTimeText: '',
      endTimeText: '',
      goodsName: '',
      startTime: new Date(),
      endTime: new Date()
    };
  }

  componentDidMount() {
    if (this.props.startTime) {
      this.setTime().startTime(this.props.startTime);
    }
    if (this.props.endTime) {
      this.setTime().endTime(this.props.endTime);
    }
  }

  setTime() {
    return {
      endTime: (v: Date | Moment) => {
        this.setState({
          endTime: moment(v).toDate(),
          endTimeText: moment(v).format('YYYY/MM/DD')
        });
      },
      startTime: (v: Date | Moment) => {
        this.setState({
          startTime: moment(v).toDate(),
          startTimeText: moment(v).format('YYYY/MM/DD')
        });
      }
    };
  }

  showDatePicker(type: 'startTime' | 'endTime') {
    this.openType = type;
    this.setState({
      timePickerVisible: true
    });
  }

  close = () => {
    this.setState({
      timePickerVisible: false
    });
  }

  timeValueChange(v: Date) {
    if (this.openType === 'startTime') {
      v.setHours(0);
      v.setMinutes(0);
      this.setState({
        startTimeText: moment(v).format('YYYY/MM/DD'),
        startTime: v
      });
    } else {
      v.setHours(23);
      v.setMinutes(59);
      this.setState({
        endTimeText: moment(v).format('YYYY/MM/DD'),
        endTime: v
      });
    }
  }

  goodsChange (e: any) {
    this.setState({
      goodsName: e.target.value
    })
  }

  callback = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.state);
    }
  }

  render() {
    return (
      <div className="timePicker">
        <div className="content">
          <div className="timeShow">
            <img src="" alt="" className="timeIcon"></img>
            <label className="timeLable">商品名称</label>
            <input className="searchName" value={this.state.goodsName} onChange={(e) => {this.goodsChange(e)}} />
          </div>
          <div className="timeShow" onClick={this.showDatePicker.bind(this, 'startTime')}>
            <img src={timeIcon} alt="" className="timeIcon"></img>
            <label className="timeLable">开始时间</label>
            <label className="timeValue">{this.state.startTimeText || "选择时间"}</label>
            <img src={arrive} alt="" className="downArrive"></img>
          </div>
          <div className="timeShow" onClick={this.showDatePicker.bind(this, 'endTime')}>
            <img src={timeIcon} alt="" className="timeIcon"></img>
            <label className="timeLable">结束时间</label>
            <label className="timeValue">{this.state.endTimeText || "选择时间"}</label>
            <img src={arrive} alt="" className="downArrive"></img>
          </div>
          <div className="flex flexCenter">
            <button className="inquiryButton" onClick={this.callback}>查询</button>
          </div>
        </div>
        <DatePicker
          visible={this.state.timePickerVisible}
          onOk={this.close}
          title={this.openType === 'startTime'? '开始时间': '结束时间'}
          mode="date"
          onDismiss={() => this.close()}
          onChange={(v: Date) => this.timeValueChange(v)}></DatePicker>
      </div>
    )
  }
}
