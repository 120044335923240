import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './inbound-inquiry.scss';
import TimePicker from '../../components/time-picker/time-picker5';
import storeImg from '../../assets/img/repository/storeIcon.png';
import moment from 'moment';
import { InboundService } from '../../service/inbound.service';
import { PageOption } from '../../model';
import NoResult from '../../components/no-result/no-result';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import download from '../../assets/img/download.png';
import { Navigate } from '../../tools/navigate';
import { Modal, List, Button, Toast } from 'antd-mobile';
import ClipboardJS from 'clipboard';

@observer
export default class InboundInquiry extends PureComponent<any, any> {

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  @observable noResult: boolean = false;

  query: {start_time: string, end_time: string, goods_name: string};

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      downloadVisible: false,
      currentUrl: '',
      loading: false,
      sales: 0,
      sum: 0,
      total_fee: 0
    };
    this.query = {
      goods_name: '',
      start_time: this.formatDate(moment(new Date()).hour(0).minute(0)),
      end_time: this.formatDate(moment(new Date()).hour(23).minute(59))
    };
    this.getList();
    const clipBoard = new ClipboardJS('.inboundInquiryBtn');
    clipBoard.on('success', () => {
      Toast.info('复制成功！');
      this.setState({
        downloadVisible: false
      })
    })
  }

  async getList() {
    if (!this.state.loading) {
      this.noResult = false;
      const data = await InboundService.getListV2(this.pageOption, this.query);
      this.pageOption.page = data.list.current_page;
      this.pageOption.rows = +data.list.per_page;
      this.pageOption.total = +data.list.total;
      this.setState({
        dataList: [...this.state.dataList, ...data.list.data],
        sales: data.sales,
        sum: data.sum,
        total_fee: data.total_fee,
      });
      this.setState({
        loading: false
      });
      if (!this.state.dataList.length) {
        this.noResult = true;
      }
    }
  }

  timeSearch = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.query.goods_name = v.goodsName;
    this.resetSearch();
  }

  resetSearch() {
    this.pageOption.page = 1;
    this.setState({
      dataList: []
    }, () => {
      this.getList();
    });
  }

  pageSearch() {
    this.pageOption.page++;
    this.setState({
      loading: true
    });
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.pageOption.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  downLoad = async () => {
    Toast.loading('加载中...', 0);
    const data = await InboundService.download(this.query.start_time, this.query.end_time);
    this.setState({
      downloadVisible: true,
      currentUrl: data.url
    });
    Toast.hide();
  }

  toDetail(item: any, query: any) {
    Navigate.route.push(`/inboundDetail/${item.goodscode}/${query.start_time}/${query.end_time}`);
  }

  copyUrl = () => {

  }

  inquiryList() {
    return this.state.dataList.map((item: any, index: number) => {
      return <div className="item" key={index} onClick={this.toDetail.bind(this, item, this.query)}>
        <div className="medName">
          <img src={storeImg} alt=""></img>
          <div className="name">{item.goodsname + (item.name? `(${item.name})`: '')}</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          {/*<div className="param">
            <div className="paramName">单据ID</div>
            <div className="paramValue">{item.billno}</div>
          </div>
          <div className="param">
            <div className="paramName">明细序号</div>
            <div className="paramValue">{item.billsn}</div>
          </div>
          <div className="param">
            <div className="paramName">单据编号</div>
            <div className="paramValue">{item.billcode}</div>
          </div>
          <div className="param">
            <div className="paramName">日期</div>
            <div className="paramValue">{item.dates}</div>
          </div>
          <div className="param">
            <div className="paramName">时间</div>
            <div className="paramValue">{item.ontime}</div>
          </div>
          <div className="param">
            <div className="paramName">单位ID</div>
            <div className="paramValue">{item.businessid}</div>
          </div>
          <div className="param">
            <div className="paramName">单位名称</div>
            <div className="paramValue">{item.businessname}</div>
          </div>*/}
          <div className="param">
            <div className="paramName">商品ID</div>
            <div className="paramValue">{item.goodsid}</div>
          </div>
          <div className="param">
            <div className="paramName">商品编号</div>
            <div className="paramValue">{item.goodscode}</div>
          </div>
          <div className="param">
            <div className="paramName">商品名称</div>
            <div className="paramValue">{item.goodsname}</div>
          </div>
          <div className="param">
            <div className="paramName">规格</div>
            <div className="paramValue">{item.goodsspec}</div>
          </div>
          <div className="param">
            <div className="paramName">生产厂家</div>
            <div className="paramValue">{item.manufacturer}</div>
          </div>
          <div className="param">
            <div className="paramName">产地</div>
            <div className="paramValue">{item.place}</div>
          </div>
          <div className="param">
            <div className="paramName">剂型</div>
            <div className="paramValue">{item.formula}</div>
          </div>
          {/*<div className="param">
            <div className="paramName">数量</div>
            <div className="paramValue">{item.num}</div>
          </div>
          <div className="param">
            <div className="paramName">批号</div>
            <div className="paramValue">{item.batchcode}</div>
          </div>
          <div className="param">
            <div className="paramName">生产日期</div>
            <div className="paramValue">{item.producedate}</div>
          </div>
          <div className="param">
            <div className="paramName">有效期至</div>
            <div className="paramValue">{item.valdate}</div>
          </div>*/}
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="inboundInquiry" onScroll={this.scrollLoad} style={{paddingTop: "0"}}>
        <div className="header">
          <NavBar title="入库查询"></NavBar>
          <TimePicker onSearch={this.timeSearch}  startTime={this.query.start_time} endTime={this.query.end_time}></TimePicker>
        </div>
        <div className="inboundListPool">
          <div>
            <span>订单数</span>
            <span>{this.state.sum}</span>
          </div>
          <div>
            <span>销量</span>
            <span>{this.state.sales}</span>
          </div>
          <div>
            <span>销售总价</span>
            <span>￥{this.state.total_fee}</span>
          </div>
        </div>
        <div className="inboundInquiryList">
          { this.inquiryList() }
          { this.noResult && <NoResult></NoResult>}
        </div>
        <img className="downLoad" alt="download" src={download} onClick={this.downLoad}/>
        <Modal
          popup = {true}
          visible={this.state.downloadVisible}
          onClose={() => this.setState({downloadVisible: false})}
          animationType="slide-up"
        >
          <List renderHeader={() => <div className="inboundInquiryInputUrlTitle">复制下载地址</div>} className="popup-list">
            <div className="inboundInquiryInputUrl">
              <div className="tip">(请复制连接到手机自带浏览器进行下载)</div>
              <input id="inboundInquiryInputUrl" readOnly defaultValue={this.state.currentUrl} />
            </div>
            <List.Item>
              <Button type="primary" className="inboundInquiryBtn" onClick={this.copyUrl} data-clipboard-action="copy" data-clipboard-target="#inboundInquiryInputUrl">复制地址</Button>
            </List.Item>
          </List>
        </Modal>
      </div>
    )
  }
}
