import { Http } from "./core/http";

export class Report {

  private static http: Http = new Http();

  public static send(content: string) {
    return this.http.post('/report/commit', { content });
  }
}
