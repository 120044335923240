import { observable } from 'mobx';

const appStore = observable({
  createOrder: {
    id: null,
    medicineList: [],
    cilent: null,
    remark: '',
    imgList: [],
    cacheMedicineList: []
  },
  checkedMedicines: [],
  selectedCustomer: null,

  orderListTime: {
    startTime: null,
    endTime: null,
    state: ''
  },

  flowQuiryTime: {
    startTime: null,
    endTime: null
  }

});

export default appStore;
