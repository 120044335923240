import { Http } from "./core/http";
import { Storage } from "./core/storage";

export class LoginService {

  private static http: Http = new Http();

  static login(username: string, password: string) {
    return this.http.post('/wechat/bandAccount', { username, password, open_id: Storage.get('openId')});
  }

  static logout() {
    return this.http.post('/wechat/logout');
  }

  static isLogin() {
    return this.http.post('/wechat/isBind');
  }
}
