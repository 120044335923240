import { Http } from "./core/http";
import { SalesmanQuery } from "../model";

export class SalesmanService {

  private static http: Http = new Http();

  static clerkList( query: SalesmanQuery) {
    return this.http.post('/clerk/getList', query);
  }

  static isCreate( user_id: number, state: number) {
    return this.http.post('/clerk/isCreate', { user_id, state });
  }
}
