import React, { PureComponent } from "react";
import './tabs.scss';
import { TabItem } from "../../model";

export default class Tabs extends PureComponent<any, any> {

  static defaultProps = {
    tabs: [],
    index: 0
  };

  constructor(props: any) {
    super(props);
    this.state = {
      selectedIndex: 0
    }
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      selectedIndex: this.props.index || 0
    })
  }

  select(index: number) {
    this.setState({
      selectedIndex: index
    });
    if (this.props.onChange) {
      this.props.onChange(index);
    }
  }

  componentWillUpdate() {
    
  }

  render() {
    return (
      <div className="tabs">
        {
          this.props.tabs.map((item: TabItem, index: number) => {
            return <div className={this.state.selectedIndex === index? 'selectTab tabBar': 'tabBar'} key={index} onClick={this.select.bind(this, index)}>
            <div className="tabTitle">{item.title}</div>
             {this.state.selectedIndex === index && <div className="tabLine"></div>}
          </div>
          })
        }
      </div>
    )
  }
}