import React, { PureComponent, Props } from 'react';
import './App.scss';
import { Router, Route, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';
import Home from './pages/home/home';
import { LoadingPage } from './pages/loading-page/loading-page';
import User from './pages/user/user';
import { Login } from './pages/login/login';
import Feedback from './pages/feedback/feedback';
import Issue from './pages/issue/issue';
import ModifyPassword from './pages/modify-password/modify-password';
import OrderList from './pages/order-list/order-list';
import { Repository } from './pages/repository/repository';
import RepositoryResult from './pages/repository/repository-result/repository-result';
import RepositoryResultDetail from './pages/repository/repository-result/repository-result-dedail/repository-result-dedail';
import { Salesman } from './pages/salesman/salesman';
import SalesmanList from './pages/salesman/salesman-list/salesman-list';
import CreateOrder from './pages/create-order/create-order';
import CustomerList from './common/customer-list/customer-list';
import GoodsList from './common/goods-list/goods-list';
import GoodsList4business from './common/goods-list/goods-list4business'
import FlowInquiry from './pages/flow-inquiry/flow-inquiry';
import FlowDetail from './pages/flow-inquiry/flow-detail/flow-detail';
import InboundInquiry from './pages/inbound-inquiry/inbound-inquiry';
import InboundDetail from './pages/inbound-inquiry/inbound-detail/inbound-detail';
import PurchaseNotice from './pages/purchase-notice/purchase-notice';
import ReportStatistics from './pages/report-statistics/report-statistics'
import ReportDetail from './pages/report-statistics/report-dedail/report-dedail'
import SalesStatistics from './pages/sales-statistics/sales-statistics'
import OrderDetail from './pages/order-list/order-detail/order-detail';
import NoticeDetail from './pages/purchase-notice/notice-detail/notice-detail';
import Dobusiness from './pages/do-business/do-business'
import Logistics from './pages/logistics/logistics';
import IssueDetail from './pages/issue/issue-detail/issue-detail';
import CommisionList from './pages/commision-list/commision-list'
// import { Provider as KeepAliveProvider, KeepAlive } from 'react-keep-alive';
import Listener from './service/core/global.service';
interface AppState {
  supportCacheComponents: Set<string>;
}

class App extends PureComponent<any, AppState> {

  constructor(props: Props<any>) {
    super(props);
    this.state = {
      supportCacheComponents: new Set<string>(['orderList'])
    };
  }

  componentDidMount() {
    this.clearComponentCache();
  }

  clearComponentCache() {
    Listener.clearComponentCacheTrigger.subscribe(({name, disabled}) => {
      console.log(name, disabled);
      this.setState(({ supportCacheComponents }) => {
        const cache = new Set(supportCacheComponents);
        if(disabled) {
          cache.add(name);
        } else {
          cache.delete(name);
        }
        return {
          supportCacheComponents: cache
        };
      });
    });
  }

  keepAliveDisabled(name: string): boolean {
    return this.state.supportCacheComponents.has(name);
  }

  render() {
    return (
      <Router history={createHashHistory()}>
        {/* <KeepAliveProvider include={['orderList']}> */}
          <Switch>
            {/* 主页面 */}
            <Route exact path="/" component={LoadingPage}></Route>
            <Route path="/home" component={Home}></Route>
            <Route path="/user" component={User}></Route>
            <Route path="/login" component={Login}></Route>
            <Route path="/feedback" component={Feedback}></Route>
            <Route path="/issue" component={Issue}></Route>
            <Route path="/issueDetail/:issueID" component={IssueDetail}></Route>
            <Route path="/modifyPassword" component={ModifyPassword}></Route>
            <Route path="/orderList">

              {/* <KeepAlive name="orderList" disabled={this.keepAliveDisabled('orderList')}> */}
                <OrderList />
              {/* </KeepAlive> */}
            </Route>
            <Route path="/commisionList">

              {/* <KeepAlive name="commisionList" disabled={this.keepAliveDisabled('commisionList')}> */}
                <CommisionList />
              {/* </KeepAlive> */}
            </Route>
            <Route path="/flowDetail/:goods_num/:start_time/:end_time" component={FlowDetail}></Route>
            <Route path="/inboundDetail/:goodscode/:start_time/:end_time" component={InboundDetail}></Route>
            <Route path="/orderDetail/:orderID" component={OrderDetail}></Route>
            <Route path="/noticeDetail/:noticeID" component={NoticeDetail}></Route>
            <Route path="/createOrder" component={CreateOrder}></Route>
            <Route path="/repository" component={Repository}></Route>
            <Route path="/repositoryResult" component={RepositoryResult} ></Route>
            <Route path="/repositoryResultDetail/:goods_code" component={RepositoryResultDetail} ></Route>
            <Route path="/salesman" component={Salesman}></Route>
            <Route path="/salesmanList" component={SalesmanList} ></Route>
            <Route path="/logistic/:orderID" component={Logistics}></Route>
            <Route path="/flowInquiry" component={FlowInquiry}></Route>
            <Route path="/inboundInquiry" component={InboundInquiry}></Route>
            <Route path="/purchaseNotice" component={PurchaseNotice}></Route>
            <Route path="/reportStatistics" component={ReportStatistics}></Route>
            <Route path="/reportDetail/:admin_id/:start_time/:end_time" component={ReportDetail}></Route>
            <Route path="/salesStatistics" component={SalesStatistics}></Route>
            <Route path="/doBusiness" component={Dobusiness}></Route>
            {/* 公共业务页面 */}
            <Route path="/selectMedicine" component={GoodsList}></Route>
            <Route path="/selectMedicine4business" component={GoodsList4business}></Route>
            <Route path="/selectCustomer" component={CustomerList}></Route>
            
          </Switch>
        {/* </KeepAliveProvider> */}
      </Router>
    );
  }
}

export default App;
