import { Http } from "./core/http";

export class IssueService {
  protected static http: Http = new Http();

  static getList() {
    return this.http.post('/faq/getList');
  }

  static detail(faq_id: number) {
    return this.http.post('faq/detail', { faq_id });
  }
}
