
import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import "./create-order.scss";
import { observer, inject } from 'mobx-react';
import { action, computed } from 'mobx';
import { ImagePicker, Toast } from 'antd-mobile';
import storeIcon from '../../assets/img/cilent-store.png';
import arriveIcon from '../../assets/img/left-arrow.png';
import unchecked from '../../assets/img/unchecked.png';
import checked from '../../assets/img/checked.png';
import plusIcon from '../../assets/img/plus.png';
import deleteIcon from '../../assets/img/deleteIcon.png';
import { Navigate } from '../../tools/navigate';
import { OrderService } from '../../service/order.service';
import { Tools } from '../../tools';
import medicineIcon from '../../assets/img/my-order/medicine.png';

@inject('store')
@observer
export default class CreateOrder extends PureComponent<any, any> {

  orderData = {
    medicineList: [],
    cilent: null,
    remark: '',
    imgList: [],
  };

  constructor(props: any) {
    super(props);
    this.state = {
      files: [],
      medicineList: [],
      remark: '',
      client: null,
      checkedAll: false
    };
  }

  async componentDidMount() {
    const { store } = this.props;
    this.createOrderData.medicineList.forEach((item: any) => {
      item.checked = false;
      // item.amount = 1;
    });
    const medicineList = this.loadingCacheList();
    this.setState({
      medicineList,
      files: store.createOrder.imgList,
      remark: store.createOrder.remark,
      client: store.createOrder.cilent,
    });
    this.setMedicineList(medicineList);
  }

  componentWillUnmount() {
    this.setOrderDataCache();
  }

  @action
  setMedicineList(list: any[]) {
    this.createOrderData.medicineList = list;
  }

  @action
  setOrderDataCache() {
    this.createOrderData.medicineList = this.state.medicineList;
    this.createOrderData.remark = this.state.remark;
    this.props.store.checkedMedicines = [];
  }

  loadingCacheList() {
    const source = this.createOrderData.medicineList;
    const cache = this.props.store.checkedMedicines;
    cache.forEach((item: any) => {
      if(item.price) {
        item.price = +item.price;
      }
    });
    const data: any[] = [];
    source.forEach((item: any) => {
      // const findItem = cache.find((v: any) => v.id === item.id);
      // if (findItem) {
      //   item.amount = findItem.amount? findItem.amount: (item.amount + 1);
      // }
      data.push(item);
    });
    // 把不在原列表的数据加到状态列表里
    cache.forEach((item: any) => {
      // if (!source.find((v: any) => item.id === v.id)) {
      // }
      if (!item.amount) {
        item.amount = 1;
      }
      data.push(item);
    });
    return data;
  }

  @computed get createOrderData() {
    return this.props.store.createOrder;
  }

  @computed
  get amount() {
    let amount = 0;
    this.createOrderData.medicineList.forEach((item: any) => {
      if (item.price && item.amount && item.checked) {
        amount+= ((Number(item.price) * 100) * Number(item.amount))
      }
    });
    return (amount / 100).toFixed(2);
  }

  @action
  setMedicine = () => {
  }

  @action.bound
  setRemark({target: { value }}: any) {
    this.setState({
      remark: value
    });
    this.orderData.remark = value;
    this.props.store.createOrder.remark = value;
  }

  @action
  imgChange = (files: any) => {
    this.setState({
      files,
    });
    this.createOrderData.imgList = files;
  }

  toSelectClientPage = () => {
    Navigate.route.push('/selectCustomer');
  }

  toSelectGoods = () => {
    Navigate.route.push('/selectMedicine');
  }

  @action
  checkedmedicine = (index: number) => {
    const { store } = this.props;
    const result = !store.createOrder.medicineList[index].checked;
    store.createOrder.medicineList[index].checked = result;
    const data = JSON.parse(JSON.stringify(this.state.medicineList));
    data[index].checked = result;
    this.setState({
      medicineList: data
    });
  }

  @action
  setPrice = (event: any, index: number) => {
    const value = +event.target.value < 0? 0: event.target.value;
    this.createOrderData.medicineList[index].price = value;
    const data = JSON.parse(JSON.stringify(this.state.medicineList))
    data[index].price = value;
    this.setState({
      medicineList: data
    })
  }

  @action
  setCount = (event: any, index: number) => {
    const value = +event.target.value < 0? 0: event.target.value;
    this.createOrderData.medicineList[index].amount = value;
    const data = JSON.parse(JSON.stringify(this.state.medicineList))
    data[index].amount = value;
    this.setState({
      medicineList: data
    })
  }

  @action 
  checkAllGoods = () => {
    const result = !this.state.checkedAll;
    this.createOrderData.medicineList.forEach((item: any) => {
      item.checked = result;
    });
    const data = JSON.parse(JSON.stringify(this.state.medicineList));
    data.forEach((item: any) => {
      item.checked = result;
    });
    this.setState({
      medicineList: data,
      checkedAll: result
    });
  }

  @action
  deleteGoods = () => {
    const list: any[] = [];
    this.createOrderData.medicineList.forEach((item: any) => {
      if (!item.checked) {
        list.push(item);
      }
    });
    this.props.store.createOrder.medicineList = list;
    this.setState({
      medicineList: list,
      checkedAll: false
    });
    Toast.info('删除成功');
  }

  submit = async () => {
    let msg = '';
    if (!this.createOrderData.cilent) { msg = '请选择客户';}
    if (!this.createOrderData.medicineList.length) { msg = '请选择商品';}
    if (!this.createOrderData.medicineList.some((item: any) => item.checked)) { msg = "请勾选商品"; }
    this.createOrderData.medicineList.forEach((item: any) => {
      if ((!item.price || !item.amount) && item.checked) {
        msg = '请填写选中商品数量以及单价'
      }
    });
    if(msg) {
      Toast.offline(msg);
    } else {
      Toast.loading('提交订单中...', 0);
      const url = this.createOrderData.imgList.filter((img: any) => /^(http|https).*/.test(img.url)).map((item: any) => item.url);
      const base64 = this.createOrderData.imgList.filter((img: any) => !/^(http|https).*/.test(img.url)).map((item: any) => item.url);
      const data = {
        order_id: this.createOrderData.id,
        business_name: this.createOrderData.cilent.name,
        business_id: this.createOrderData.cilent.business_id,
        description: this.createOrderData.remark,
        goodsArr: JSON.stringify(this.createOrderData.medicineList.filter((item: any) => item.checked)),
        img: base64.map((img: string) => Tools.base64toBlob(img)),
        url
      };
      // console.log(data)
      if (data.order_id) {
        await OrderService.resubmit(data);
      } else {
        await OrderService.createOrder(data);
      }
      // Toast.hide();
      Navigate.route.goBack();
    }
  }

  medicineList = () => {
    return this.state.medicineList.map((item: any, index: number) => {
      return <div className="medicineItem" key={index}>
      <div className="top">
          <img onClick={this.checkedmedicine.bind(this, index)} className="checkbox" src={item.checked? checked: unchecked} alt=""></img>
        <div className="medicineLable">
        </div>
          <img className="reposIcon" src={medicineIcon} alt=""></img>
      </div>
      <div className="bottom">
        <div className="price">
          {item.goods_name}
          {item.name? `(${item.name})`: ''}
        </div>
        <div className="price">
          <label>规格</label>
          <div>
            {item.goods_spec}
          </div>
        </div>
        <div className="price">
          <label>开票价</label>
          <div>
            ￥{parseFloat(item.taxprice)}
          </div>
        </div>
        <div className="price">
          <label>数量</label>
          <div>
            <input type="number" min="0" value={item.amount || ''} onChange={(v: any) => this.setCount(v, index)}/>
            <label>{item.unit}</label>
          </div>
        </div>
        <div className="price">
          <label>单价</label>
          <div>
            <input type="number" min="0" value={item.price || ''} onChange={(v: any) => this.setPrice(v, index)} />
            <label>元</label>
          </div>
        </div>
      </div>
    </div>
    })
  }

  render() {
    const { files } = this.state;
    return (
      <div className="createOrder">
        <div className="header">
          <NavBar title={this.createOrderData.id? '重新提交': '创建订单'}></NavBar>
        </div>
        <div className="orderBody">
          <div className="orderBlock">
            <div className="name cilentName" onClick={this.toSelectClientPage}>
              <div className="clientLabel">
                <img className="storeIcon" src={storeIcon} alt=""></img>
                客户名称
              </div>
              <div  className="selectArrive">
                {this.props.store.createOrder.cilent? this.props.store.createOrder.cilent.name: '请选择'}
                <img className="arriveIcon" src={arriveIcon} alt=""></img>
              </div>
            </div>
            <div className="content">
              { this.medicineList() }
              <div className="addGoods flex flexCenter">
                <div className="flex flexCenter" onClick={this.toSelectGoods}>
                  <img className="plusIcon" src={plusIcon} alt=""></img>
                  添加商品
                </div>
                <div className="deleteIcon flexCenter flex" onClick={this.deleteGoods}>
                  <img className="plusIcon" src={deleteIcon} alt=""></img>
                  删除商品
                </div>
              </div>
            </div>
          </div>
          <div className="orderBlock">
            <div className="name">
              添加备注
              <label className="tip">（选填）</label>
            </div>
            <div className="content">
              <textarea
                className="textarea"
                placeholder="请输入问题描述"
                value={this.state.remark}
                onChange={(v: any) => this.setRemark(v)}
              ></textarea>
            </div>
          </div>
          <div className="orderBlock">
            <div className="name">
              添加图片
              <label className="tip">（选填）</label>
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files}
                  onChange={this.imgChange}
                  onImageClick={(index, fs) => console.log(index, fs)}
                  selectable={files.length < 5}
                />
              </div>
            </div>
          </div>
        </div>
        <div className=" createOrderSubmit">
          <div className="left">
            <img className="allCheck" src={this.state.checkedAll? checked: unchecked} alt="" onClick={this.checkAllGoods}></img>
            <div className="allCheckLabel">全选</div>
            <div className="count">总计
            <label className="amount">￥{this.amount}</label>
            </div>
          </div>
          <div className="right">
            <div className="submit flex flexCenter" onClick={this.submit}>提交</div>
          </div>
        </div>
      </div>
    )
  }
}
