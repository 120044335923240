import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './customer-list.scss';
import SearchBar from '../../components/search-bar/search-bar';
import { CustomerService } from '../../service/customer.service';
import moment from 'moment';
import customerIcon from '../../assets/img/cilent-store.png';
import { Customer, PageOption } from '../../model';
import selectedIcon from '../../assets/img/selected.png';
import unSelectedIcon from '../../assets/img/unselected.png';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Navigate } from '../../tools/navigate';
import BottomRefresh from '../../components/bottom-refresh/bottom-refresh';
import { Accordion, List, Toast } from 'antd-mobile'

@inject('store')
@observer
export default class CustomerList extends PureComponent<any, any> {

  customerList: Customer[] = [];

  name: string = '';

  pageQuery: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  inputInterval: NodeJS.Timeout = setTimeout(() => {}, 0);

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      selectedIndex: -1,
      isOverDate: false,
      loading: false
    };
  }
  componentDidMount() {
    this.getList()
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
        return
    }
  }
  getList = async () => {
    this.setState({loading: true});
    const data = await CustomerService.getList(this.pageQuery, {name: this.name});
    data.data.map((item: any) => {
      item.isOverDate = false;
      item.cert.map((cert: any) => {
        if (this.formatDate(new Date()) > cert.valdate) {
          item.isOverDate = true;
        }
      })
    })
    this.setState({loading: false});
    this.pageQuery.page = data.current_page;
    this.pageQuery.rows = +data.per_page;
    this.pageQuery.total = data.total;
    this.setState({
      dataList: [...this.state.dataList, ...data.data]
    });
    this.customerList = this.state.dataList;
  }


  selectCustomer(index: number, isOverDate: boolean) {
    this.setState({
      selectedIndex: index,
      isOverDate: isOverDate
    });
  }

  setQueryName = (v: string) => {
    this.name = v;
  }

  inputSearch = (v: string) =>{
    this.name = v;
    clearTimeout(this.inputInterval);
    this.inputInterval = setTimeout(() => {
      this.nameSearch();
    }, 300);
  }

  pageSearch = () => {
    this.pageQuery.page++;
    this.getList();
  }

  nameSearch = () => {
    this.pageQuery.page = 1;
    this.setState({
      dataList: [],
      selectedIndex: -1,
      isOverDate: false
    });
    this.getList();
  }

  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.pageQuery.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  @action
  submitData = () => {
    if (this.state.isOverDate) {
      Toast.fail('选择的客户证件过期！请重新选择')
    } else {
      const cilent = this.customerList[this.state.selectedIndex];
      this.props.store.createOrder.cilent = cilent;
      this.props.store.selectedCustomer = cilent;
      Navigate.route.goBack();
    }
  }

  repositoryList() {
    return this.state.dataList.map((item: any, index: number) => {
      return <div className="item" key={index} onClick={this.selectCustomer.bind(this, index, item.isOverDate)}>
        <div className="medName">
          <img src={customerIcon} alt=""></img>
          <div className={item.isOverDate ? 'name red': 'name'}>{item.name }</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">{item.contacts}</div>
          </div>
          <div className="param">
            <div className="paramName">{item.phone}</div>
          </div>
          <br/>
          <div className="param">
            <div className="paramName">{item.address}</div>
          </div>
          <img src={index === this.state.selectedIndex? selectedIcon: unSelectedIcon} alt="" className="selectIcon"></img>
        </div>
        <div className="line"></div>
        <Accordion className="certParams">
          <Accordion.Panel header="点击查看证书">
            <List className="my-list">
              {item.cert.map((cert: any, index: number) => {
                return <List.Item key={index}>
                  <div className="certName">
                    <span>{cert.certname}</span>
                    {
                      this.formatDate(new Date()) > cert.valdate &&
                      <span className="red" style={{ marginLeft: 10 }}>已过期</span>
                    }
                  </div>
                  <div className="certTime">
                    <span>有效期：{cert.valdate}</span>
                  </div>
                </List.Item>
              })}
            </List>
          </Accordion.Panel>
        </Accordion>
      </div>;
    });
  }

  render() {
    return (
      <div className="customerList" onScroll={this.scrollLoad}>
        <div className="repostHeader">
          <NavBar title="客户查询"></NavBar>
          <SearchBar placeholder="请输入要客户名" onChange={this.setQueryName} onInput={this.inputSearch} onSearch={this.nameSearch}></SearchBar>
        </div>
        <div className="reposReultList">
          {this.repositoryList()}
        </div>
        <BottomRefresh
          total={this.pageQuery.total}
          infactTotal={this.state.dataList.length}
          isLoading={this.state.loading}
          onSearch={this.pageSearch}></BottomRefresh>
        <button className="goodsListBtton" onClick={this.submitData}>加入订单</button>
      </div>
    )
  }

}
