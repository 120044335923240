import { PureComponent } from "react";
import React from 'react';
import './nav-bar.scss';
import { Navigate } from "../../tools/navigate";
import LeftArrow from '../../assets/img/left-arrow.png';

export default class NavBar extends PureComponent<any> {

  back = () => {
    if (Navigate.route.location.pathname.includes('/noticeDetail/')) {
        Navigate.route.push('/purchaseNotice');
    } else if (Navigate.route.location.pathname === '/purchaseNotice') {
        Navigate.route.push('/home');
    } else {
        Navigate.route.goBack();
    }
  }

  render() {
    return (
      <div className="navbar" style={{
            backgroundColor: this.props.bgColor || '#fff',
            color: this.props.color || '#666'
          }}>
        <span onClick={this.back}>
          <img src={LeftArrow} alt="" className="icon"></img>
        </span>
        <label className="title">{this.props.title}</label>
      </div>
    )
  }
}
