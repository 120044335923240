import React, { PureComponent } from "react";
import NavBar from "../../components/navBar/nav-bar";
import { List, InputItem } from "antd-mobile";
import './salesman.scss';
import { SalesmanQuery } from "../../model";
import { Link } from "react-router-dom";

export class Salesman extends PureComponent {

  submitData: SalesmanQuery = {
    keyword: '',
    page: 1,
    rows: 20,
    total: 0
  };

  changeData = (value: any, type: 'keyword') => {
    this.submitData[type] = value;
  }

  render() {
    return <div className="repository">
      <NavBar title="库存查询"></NavBar>
      <List className="repository-List">
        <InputItem
              type='text'
              placeholder="商品名称"
              moneyKeyboardAlign="right"
              onChange={(v) => this.changeData(v, 'keyword')}
            >商品名</InputItem>
      </List>
      <div className="flex flexCenter">
      <Link to={{ pathname:'repositoryResult', state : this.submitData}}>
        <button className="submitButton">查询库存</button>
      </Link>
      </div>
    </div>
  }

}
