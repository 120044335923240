import { PureComponent } from "react";
import React from 'react';
import './feedback.scss';
import NavBar from "../../components/navBar/nav-bar";
import { List, TextareaItem } from "antd-mobile";
import { Report } from "../../service/report.service";
import { Navigate } from "../../tools/navigate";

export default class Feedback extends PureComponent {

  private feedback: string = '';

  setMsg (feedback: string) {
    this.feedback = feedback;
  }

  submit = async () => {
    await Report.send(this.feedback);
    Navigate.route.goBack();
  }

  render() {
    return (
      <div className="feedback">
        <NavBar title="意见反馈"></NavBar>
        <List className="textarea" renderHeader={() => '请输入问题描述'}>
          <TextareaItem
            placeholder="请输入问题描述"
            onChange={(v: any) => this.setMsg(v)}
            rows={5}
            count={180}
          />
        </List>
        <button className="submitButton" onClick={this.submit}>提交反馈</button>
      </div>
    )
  }
}
