import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './report-statistics.scss';
import TimePicker from '../../components/time-picker/time-picker';
import TimePicker1 from '../../components/time-picker/time-picker1'
import moment from 'moment';
import { LogisticsService } from '../../service/logistics.service1';
import { PageOption } from '../../model';
import NoResult from '../../components/no-result/no-result';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, List, Button, Toast } from 'antd-mobile';
import ClipboardJS from 'clipboard';
import { Tools } from '../../tools';
import { Navigate } from '../../tools/navigate';


@observer
export default class FlowInquiry extends PureComponent<any, any> {

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  @observable noResult: boolean = false;

  query: {start_time: string, end_time: string};

  constructor(props: any) {
    super(props);
    this.state = {
      dataList1: [],
      downloadVisible: false,
      currentUrl: '',
      flag: true,
      dingdan :[],
      jine:[],
      isShow: "block",
      isShow1: "none"
    };
    this.query = {
      start_time: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      end_time: this.formatDate(moment(new Date()).hour(23).minute(59))
    };
    const clipBoard = new ClipboardJS('.flowInquiryBtn');
    clipBoard.on('success', () => {
      Toast.info('复制成功！');
      this.setState({
        downloadVisible: false
      })
    })
  }
  toDetail(item: any ,query: any) {
    Navigate.route.push(`/reportDetail/${item}/${query.start_time}/${query.end_time}`);
  }
  async getList() {
    this.noResult = false;
    const data = await LogisticsService.getList(this.pageOption, this.query);
    this.pageOption.page = data.current_page;
    this.pageOption.rows = +data.per_page;
    this.pageOption.total = +data.total;
    let array1:any[] = []
    data.data.forEach(function(item:any){
      array1.push({value:Number(item.order_total),name: item.sales_man},)
    })
    let array2:any[] = []
    data.data.forEach(function(item:any){
      array2.push({value:Number(item.fee_total),name: item.sales_man},)
    })
    this.setState({
      dataList1: [...this.state.dataList1, ...data.data]
    });
    this.setState({
      dingdan: array1
    })
    this.setState({
      jine: array2
    })
    if (!this.state.dataList1.length) {
      this.noResult = true;
    }
    

   

  }
  async componentWillMount() {
    this.resetSearch();
  }
  timeSearch = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.resetSearch();
  }
  timeSearch1 = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.resetSearch();
  }
  resetSearch() {
    this.pageOption.page = 1;
    this.setState({
      dataList1: []
    }, () => {
      this.getList();
    });
  }
  tab1() {
      this.setState({ isShow : 'block' })
      this.setState({ isShow1 : 'none' })
  }
  tab2() {
    this.setState({ isShow : 'none' })
    this.setState({ isShow1 : 'block' })
  }
  pageSearch() {
    this.pageOption.page++;
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList1.length >= this.pageOption.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }
  changeDate() {
    this.setState({flag: !this.state.flag})
  }
  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  downLoad = async () => {
    Toast.loading('加载中...', 0);
    const data = await LogisticsService.download(this.query.start_time, this.query.end_time);
    this.setState({
      downloadVisible: true,
      currentUrl: data.url+'?'+ Tools.objectToUrlString(this.query, true)
    });
    Toast.hide();
  }

  copyUrl = () => {

  }
  chart() {
    return <div style={{ display:this.state.isShow1 }}>
              <div className="medName" style={{marginBottom:'0.5rem'}}>
                <div id="main" style={{width: '100%',height: '10rem',border: '1px solid #ddd',borderRadius:'2px',background:'#fff'}}></div>
              </div>
              <div className="medName">
                <div id="main1" style={{width: '100%',height: '10rem',border: '1px solid #ddd',borderRadius:'2px',background:'#fff'}}></div>
              </div>
          </div>
  }
  inquiryList() {
    return this.state.dataList1.map((item: any, index: number) => {
      return <div className="item" key={index} style={{ display:this.state.isShow }}>
        <div className="medName">
          <div className="name">{item.sales_man}</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">总订单数</div>
            <div className="paramValue">{item.order_total}</div>
          </div>
          <div className="price-query">
            <div className='amount red'>总价：<br/>￥{item.fee_total}</div>
            <div className="btns">
              <button className="orderBtn" onClick={this.toDetail.bind(this, item.admin_id,this.query)}>详情</button>
            </div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="flowInquiry reportStatistics" onScroll={this.scrollLoad}>
        <div className="header">
          <NavBar title="下单统计"></NavBar>
          <button className="orderBtn orderBtnTime" onClick={this.changeDate.bind(this)}>{(this.state.flag === true)?"按月":"按日"}</button>
          { (this.state.flag === true)?<TimePicker1 onSearch={this.timeSearch1}  startTime={this.query.start_time} endTime={this.query.end_time}></TimePicker1>:
          <TimePicker onSearch={this.timeSearch}  startTime={this.query.start_time} endTime={this.query.end_time}></TimePicker>}
        </div>
        <div className="reportStatisticsList">
          { this.chart() }
          { this.inquiryList() }
          { this.noResult && <NoResult></NoResult>}
        </div>
        <Modal
          popup = {true}
          visible={this.state.downloadVisible}
          onClose={() => this.setState({downloadVisible: false})}
          animationType="slide-up"
        >
          <List renderHeader={() => <div className="flowInquiryInputUrlTitle">复制下载地址</div>} className="popup-list">
            <div className="flowInquiryInputUrl">
              <div className="tip">(请复制连接到手机自带浏览器进行下载)</div>
              <input id="flowInquiryInputUrl" readOnly defaultValue={this.state.currentUrl} />
            </div>
            <List.Item>
              <Button type="primary" className="flowInquiryBtn" onClick={this.copyUrl} data-clipboard-action="copy" data-clipboard-target="#flowInquiryInputUrl">复制地址</Button>
            </List.Item>
          </List>
        </Modal>
        {/* <div className="tab">
          <div className="left" onClick={this.tab1.bind(this)}>报表</div>
          <div className="right" onClick={this.tab2.bind(this)}>图表</div>
        </div> */}
      </div>
    )
  }
}
