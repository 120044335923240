
import './navigate';

export class Tools {

  static objectToUrlString = (obj: any, encode: boolean = false) => {
    const result: string[] = [];
    for (const item in obj) {
      result.push(`${item}=${encode? encodeURIComponent(obj[item]): obj[item]}`);
    }
    return result.join('&');
  };

  static parseUrl =  () => {
    const searchHref = window.location.search.replace(/\?+/, '');
    const params = searchHref.split('&');
    const returnParam: any = {};
    params.forEach((param) => {
      const paramSplit = param.split('=');
      returnParam[paramSplit[0]] = paramSplit[1];
    });
    return returnParam;
  };

  static base64toBlob(base64: any) {
    var arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
  }

  static scaleImg(img: any): string{
    if (!img.target) {  return '';}
    let height = img.target.height;
    let width = img.target.width;
    let base64data = '';
    const can = document.createElement("canvas");
    can.height = height;
    can.width = width;
    const casimg = can.getContext('2d');
    if (casimg) {
      casimg.clearRect(0, 0,width,height);
      casimg.drawImage(img.target,0,0,width,height);
    }
    base64data=can.toDataURL('image/jpeg');                                  //获取在canvas元素中的图片截图base64编码
    let size=Math.round(this.base64toBlob(base64data).size/1024);              //获取压缩前的图片大小
    let maxsize=800;                                                        //设置压缩后的最大值
    if(size>maxsize){
        if(size<1300){
            img.target.height=Math.round(height*(3/4));
            img.target.width=Math.round(width*(3/4));
            return this.scaleImg(img)
        }
        else if(size<1800){
            img.target.height=Math.round(height*(2/3));
            img.target.width=Math.round(width*(2/3));
            return this.scaleImg(img)
        }
        else{
            img.target.height=Math.round(height/2);
            img.target.width=Math.round(width/2);
            return this.scaleImg(img)
        }
    }
    else{
        console.log('压缩后大小'+size);
        return base64data;
    }
}
}
