import React, { PureComponent } from 'react'
import NavBar from '../../../components/navBar/nav-bar';
import medicineIcon from '../../../assets/img/my-order/medicine.png';
import './notice-detail.scss';
// import TimePicker from '../../../components/time-picker/time-picker';
import storeImg from '../../../assets/img/repository/storeIcon.png';
// import moment from 'moment';
import { NoticeService } from '../../../service/notice.service';
// import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Toast } from 'antd-mobile';
// import { Navigate } from '../../../tools/navigate';
// import { Tools } from '../../../tools';

@observer
export default class NoticeDetail extends PureComponent<any, any> {

  noticeID: string;

  constructor(props: any) {
    super(props);
    this.noticeID = this.props.match.params.noticeID;
    this.state = {
      noticeDetail: [],
      noticeDetailList: [],
      firstList: []
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  async getDetail() {
    Toast.loading('加载中...')
    const detail = await NoticeService.detail(this.noticeID);
    if (detail.is_read === 0) {
      this.readDetail();
    }
    this.setState({
      noticeDetail: detail,
      noticeDetailList: detail.data,
      firstList: detail.data[0]
    });
    Toast.hide();
  }

  async readDetail() {
    await NoticeService.readMark(this.noticeID)
  }

  copyUrl = () => {

  }

  // inquiryList() {
  //   return this.state.noticeDetailList.map((item: any, index: number) => {
  //     return <div>
  //       <div className="line" style={{ marginTop: "6px", marginBottom: "6px" }}></div>
  //         <div className="param">
  //           <div className="paramName">商品编号</div>
  //           <div className="paramValue">{item.goods_code}</div>
  //         </div>
  //         <div className="param">
  //           <div className="paramName">商品名称</div>
  //           <div className="paramValue">{item.common_name}({item.goods_name})</div>
  //         </div>
  //         <div className="param">
  //           <div className="paramName">规格</div>
  //           <div className="paramValue">{item.goods_spec}</div>
  //         </div>
  //         <div className="param">
  //           <div className="paramName">生产厂家</div>
  //           <div className="paramValue">{item.manufacturer}</div>
  //         </div>
  //         <div className="param">
  //           <div className="paramName">需求数量</div>
  //           <div className="paramValue">{item.amount}</div>
  //         </div>
  //       </div>;
  //   });
  // }

  inquiryList() {
    return this.state.noticeDetailList.map((item: any, index: number) => {
      return <div className={item.checked? 'icon-detail icon-detail-gray': 'icon-detail'} key={index}>
        <img className="medicineIcon" src={medicineIcon} alt=""></img>
        <div className="detail">
          <div>{item.goods_code}</div>
          <div>{item.common_name}({item.goods_name})</div>
          <div>{item.manufacturer}</div>
        </div>
        <div className="rightData flex">
          <div className="simplyPrice flex">
            <label>
              {item.goods_spec}
            </label>
            <label>
              *{item.amount}{item.unit}
            </label>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    const { noticeDetail } = this.state;
    const { firstList } = this.state;
    return (
      <div className="noticeDetail" style={{paddingTop: "0"}}>
        <div className="header">
          <NavBar title="通知详情"></NavBar>
        </div>
        <div className="noticeDetailList">
          <div className="item">
            <div className="medName">
              <img src={storeImg} alt=""></img>
              <div className="name">{firstList.common_name}({firstList.goods_name})</div>
            </div>
            <div className="line"></div>
            <div className="detailParams">
              <div className="param">
                <div className="paramName">需求类型</div>
                <div className="paramValue">{noticeDetail.type}</div>
              </div>
              <div className="param">
                <div className="paramName">联系人</div>
                <div className="paramValue">{noticeDetail.contacts}</div>
              </div>
              <div className="param">
                <div className="paramName">联系电话</div>
                <div className="paramValue"><a href={'tel:'+ noticeDetail.phone}>{noticeDetail.phone}</a></div>
              </div>
              <div className="param">
                <div className="paramName">单位名称</div>
                <div className="paramValue">{noticeDetail.business_name}</div>
              </div>
              <div className="param">
                <div className="paramName">时间</div>
                <div className="paramValue">{noticeDetail.date}</div>
              </div>
              { this.inquiryList() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
