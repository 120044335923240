import React, { PureComponent } from 'react';
import { LogisticsService } from '../../service/logistics.service';
import './logistics.scss';
import NavBar from '../../components/navBar/nav-bar';
import orderIcon from '../../assets/img/logistics/orderIcon.png';
import { OrderService } from '../../service/order.service';
import { TransprotItem } from '../../model';
import { Toast } from 'antd-mobile';
import bgImage from '../../assets/img/logistics/map-bg.jpg';

export default class Logistics extends PureComponent<any, any> {

  orderID: string;

  expressState: string[] = ['在途中', '已揽收', '疑难', '已签收', '退签', '同城派送中', '退回', '转单'];

  checkedIconCache: Map<string, any> = new Map()

  iconCache: Map<string, any> = new Map();

  constructor(props: any) {
   super(props);
   this.orderID = this.props.match.params.orderID;
   this.state = {
     detailData: {},
     dataList: []
   };
   this.setIcon();
  }

  setIcon() {
    this.checkedIconCache.set('签收', require('../../assets/img/logistics/receive-check.png'));
    this.checkedIconCache.set('在途', require('../../assets/img/logistics/transport-check.png'));
    this.checkedIconCache.set('派件', require('../../assets/img/logistics/delivery-check.png'));
    this.checkedIconCache.set('疑难', require('../../assets/img/logistics/issue-forLogistics-check.png'));
    this.checkedIconCache.set('揽收', require('../../assets/img/logistics/receive-check.png'));
    this.checkedIconCache.set('已下单', require('../../assets/img/logistics/create-order-check.png'));
    this.iconCache.set('签收', require('../../assets/img/logistics/receive.png'));
    this.iconCache.set('在途', require('../../assets/img/logistics/transport.png'));
    this.iconCache.set('派件', require('../../assets/img/logistics/delivery.png'));
    this.iconCache.set('疑难', require('../../assets/img/logistics/issue-forLogistics.png'));
    this.iconCache.set('揽收', require('../../assets/img/logistics/receive.png'));
    this.iconCache.set('已下单', require('../../assets/img/logistics/create-order.png'));
  }

  componentDidMount() {
    this.getDetail();
  }

  async getDetail() {
    Toast.loading('加载中...');
    const { create_time } = await OrderService.detail(this.orderID);
    const detail = await LogisticsService.detail(this.orderID);
    detail.data.push({time: create_time, context: '订单已创建', status: '已下单'});
    detail.data.reverse();
    detail.data.forEach((item: TransprotItem, index: number, source: TransprotItem[]) => {
      if (item.status === '在途' && source[index + 1] && source[index + 1].status === '在途') {
        item.status = '';
      }
    });
    detail.data.reverse();
    detail.data[0].check = true;
    Toast.hide();
    this.setState({
      detailData: detail,
      dataList: detail.data
    });
  }

  logisticsContent() {
    return this.state.dataList.map((item: TransprotItem) => {
      return <div className="logisticsTimespan">
        <div className="time">{item.time}</div>
        <div className="status" style={{borderColor: item.check? '#1298dc': '#ccc'}}>
          {item.check
              ? <img alt="" src={this.checkedIconCache.get(item.status)}></img>
              : item.status
                ? <img alt="" src={this.iconCache.get(item.status)}></img>
                : <div className="point"></div> }
        </div>
        <div className="statusAndContext">
          <div className="statusName">{item.status}</div>
          <div className="context">{item.context}</div>
        </div>
      </div>
    })
  }

  render() {
    return (
      <div className="logistics">
        <img className="bgImage" width="100%" src={bgImage} alt=""></img>
        <div className="header">
          <NavBar title="物流查询"></NavBar>
        </div>
        <div className="expressId">
          <img src={orderIcon} alt="" className="expressOrderIcon"></img>
          <div className="stateAndId">
            <label className="state">{this.expressState[+this.state.detailData.state]}</label><br/>
            <label className="ID">{this.state.detailData.shipping_name}：{this.state.detailData.nu}</label>
          </div>
        </div>
        <div className="logisticsContent">
          { this.logisticsContent() }
        </div>
      </div>
    );
  }
}
