import React, { PureComponent } from 'react'
import NavBar from '../../../../components/navBar/nav-bar';
import storeImg from '../../../../assets/img/repository/storeIcon.png';
import { LogisticsService } from '../../../../service/trade.search'
import { PageOption } from '../../../../model';
import NoResult from '../../../../components/no-result/no-result';
import SearchBar from '../../../../components/search-bar/search-bar';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Modal, List, Button, Toast } from 'antd-mobile';
import ClipboardJS from 'clipboard';

@observer
export default class FlowInquiry extends PureComponent<any, any> {

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  @observable noResult: boolean = false;

  query: {tag:string,goods_code:string,trade_num:string}
  
  constructor(props: any) {
    super(props);
    this.state = {
      dataList1: [],
      downloadVisible: false,
      currentUrl: '',
      flag: true,
      dingdan :[],
      jine:[],
      isShow: "block",
      isShow1: "none"
    };
    this.query = {
        tag: "wx",
        goods_code:this.props.match.params.goods_code,
        trade_num: '',
    }
    const clipBoard = new ClipboardJS('.flowInquiryBtn');
    clipBoard.on('success', () => {
      Toast.info('复制成功！');
      this.setState({
        downloadVisible: false
      })
    })
  }
  async getList() {
    console.log(this.query)
    this.noResult = false;
    const data = await LogisticsService.getList(this.pageOption,this.query);
    this.pageOption.page = data.current_page;
    this.pageOption.rows = +data.per_page;
    this.pageOption.total = +data.total;
    let array1:any[] = []
    data.data.forEach(function(item:any){
      array1.push({value:Number(item.order_total),name: item.sales_man},)
    })
    let array2:any[] = []
    data.data.forEach(function(item:any){
      array2.push({value:Number(item.fee_total),name: item.sales_man},)
    })
    this.setState({
      dataList1: [...this.state.dataList1, ...data.data]
    });
    this.setState({
      dingdan: array1
    })
    this.setState({
      jine: array2
    })
    if (!this.state.dataList1.length) {
      this.noResult = true;
    }
    

   

  }
  async componentWillMount() {
    this.resetSearch();
  }
  resetSearch() {
    this.pageOption.page = 1;
    this.setState({
      dataList1: []
    }, () => {
      this.getList();
    });
  }
  pageSearch() {
    this.pageOption.page++;
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList1.length >= this.pageOption.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }
  changeDate() {
    this.setState({flag: !this.state.flag})
  }
 
  setQueryTradNum = (v: string) => {
    this.query.trade_num = v;
  }
  nameSearch = () => {
    this.pageOption.page = 1;
    this.setState({dataList1: []});
    this.getList();
  }
  copyUrl = () => {

  }
  chart() {
    return <div style={{ display:this.state.isShow1 }}>
              <div className="medName" style={{marginBottom:'0.5rem'}}>
                <div id="main" style={{width: '100%',height: '10rem',border: '1px solid #ddd',borderRadius:'2px',background:'#fff'}}></div>
              </div>
              <div className="medName">
                <div id="main1" style={{width: '100%',height: '10rem',border: '1px solid #ddd',borderRadius:'2px',background:'#fff'}}></div>
              </div>
          </div>
  }
  inquiryList() {
    return this.state.dataList1.map((item: any, index: number) => {
      return <div className="item" key={index} style={{ display:this.state.isShow }}>
        <div className="medName">
            <img src={storeImg} alt=""></img>
          <div className="name">{item.goods_name}</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          {/*<div className="param">
            <div className="paramName">库房名称</div>
            <div className="paramValue">{item.warehouse_name}</div>
          </div>*/}
          <div className="param">
            <div className="paramName">药品批号</div>
            <div className="paramValue">{item.trade_num}</div>
          </div>
          <div className="param">
            <div className="paramName">业务库存</div>
            <div className="paramValue">{item.count}</div>
          </div>
          <div className="param">
            <div className="paramName">效期</div>
            <div className="paramValue">{item.ValDate}</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="flowInquiry reportStatistics" onScroll={this.scrollLoad}>
        <div className="header">
          <NavBar title="批号查询"></NavBar>
          <SearchBar placeholder="批号查询" onChange={this.setQueryTradNum} onSearch={this.nameSearch}></SearchBar>
        </div>
        <div className="flowInquiryList" style={{paddingTop: '2.667rem'}}>
          { this.chart() }
          { this.inquiryList() }
          { this.noResult && <NoResult></NoResult>}
        </div>
        <Modal
          popup = {true}
          visible={this.state.downloadVisible}
          onClose={() => this.setState({downloadVisible: false})}
          animationType="slide-up"
        >
          <List renderHeader={() => <div className="flowInquiryInputUrlTitle">复制下载地址</div>} className="popup-list">
            <div className="flowInquiryInputUrl">
              <div className="tip">(请复制连接到手机自带浏览器进行下载)</div>
              <input id="flowInquiryInputUrl" readOnly defaultValue={this.state.currentUrl} />
            </div>
            <List.Item>
              <Button type="primary" className="flowInquiryBtn" onClick={this.copyUrl} data-clipboard-action="copy" data-clipboard-target="#flowInquiryInputUrl">复制地址</Button>
            </List.Item>
          </List>
        </Modal>
        {/* <div className="tab">
          <div className="left" onClick={this.tab1.bind(this)}>报表</div>
          <div className="right" onClick={this.tab2.bind(this)}>图表</div>
        </div> */}
      </div>
    )
  }
}
