import { PageOption, ICustomer, IPageData } from "../model";
import { ServiceBasic } from "./core/service.basic";

export class CustomerService extends ServiceBasic {

  static async getAllMedicineData() {
    await this.database.customerTable.clear();
    const data = await this.http.post<ICustomer[]>('/Business/getList', { rows: 0});
    await this.database.customerTable.bulkAdd(data);
  }

  static async getListByDatabase(page: PageOption, query: {name: string}): Promise<IPageData<any>> {
    let result = [];
    let count = 0;
    if (query.name) {
      const name = query.name.toLocaleUpperCase();
      const nameFilter = await this.database.customerTable.filter((item => (item.name.includes(name) || item.pinyin_code.includes(name))))
      count = await nameFilter.count();
      result = await nameFilter.offset((page.page - 1) * page.rows).limit(page.rows).toArray();
    } else {
      result = await this.database.customerTable.offset((page.page - 1) * page.rows).limit(page.rows).toArray();
    }
    return Promise.resolve({
      current_page: page.page,
      per_page: page.rows,
      total: count || await this.database.customerTable.count(),
      data: result
    });
  }

  static getList(page: PageOption,query: {name: string}) {
    return this.http.post('/Business/getList', {...query, ...page});
  }

}
