import { Http } from "./core/http";
import { PageOption } from "../model";
import { Storage } from "./core/storage";

export class LogisticsService {

  protected static http: Http = new Http();

  static getList(pageOption: PageOption, query: any) {
        return this.http.post('/Goods/getGoodsTradeNum', { ...pageOption, ...query,open_id: Storage.get('openId')});
  }

  static download(start_time: string, end_time: string) {
    return this.http.post('/statement/salesman', { end_time, start_time});
  }

  static detail(order_id: string) {
    return this.http.post('/Shipping/query', { order_id });
  }

  static list() {
    return this.http.post('/Shipping/getList');
  }

  static consignment(shipping_name: string, shipping_num: string, order_id: string) {
    return this.http.post('/order/send', {shipping_name, shipping_num, order_id});
  }
}
