import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import { List } from 'antd-mobile';
import { Navigate } from '../../tools/navigate';
import { IssueService } from '../../service/issue.service';

export default class Issue extends PureComponent<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: []
    };
  }

  async componentDidMount() {
    const list  = await IssueService.getList();
    this.setState({
      dataList: list
    });
  }

  toFeedback = () => {
    Navigate.route.push('/feedback');
  }

  toDetail = (issue_id: string) => {
    Navigate.route.push(`/issueDetail/${issue_id}`);
  }

  render() {
    return (
      <div className="issue">
        <NavBar title="常见问题"></NavBar>
        <List renderHeader={() => '常见问题'} className="my-list">
          {this.state.dataList.map((item: any, index: number) => <List.Item key={index} arrow="horizontal" onClick={this.toDetail.bind(this, item.Id)}>{item.title}</List.Item>)}
        </List>
        <div className="flex flexCenter">
          <button className="submitButton" onClick={this.toFeedback}>提交反馈</button>
        </div>
      </div>
    )
  }
}
