import { RepositoryQuery, IMedicine, IPageData } from "../model";
import { ServiceBasic } from "./core/service.basic";

export class GoodsService extends ServiceBasic {

  static async getAllMedicineData() {
    await this.database.medicineTable.clear();
    const data = await this.http.post<IMedicine[]>('/Goods/getList', { rows: 0});
    await this.database.medicineTable.bulkAdd(data);
  }

  static async getListByDatabase(query: RepositoryQuery): Promise<IPageData<any>> {
    let result = [];
    let count = 0;
    if (query.goods_name) {
      const name = query.goods_name.toLocaleUpperCase();
      const nameFilter = await this.database.medicineTable.filter((item => (item.inventory.goods_name.includes(name) || item.pinyin_code.includes(name) || item.inventory.name.includes(name))))
      count = await nameFilter.count();
      result = await nameFilter.offset((query.page - 1) * query.rows).limit(query.rows).toArray();
    } else {
      result = await this.database.medicineTable.offset((query.page - 1) * query.rows).limit(query.rows).toArray();
    }
    return Promise.resolve({
      current_page: query.page,
      per_page: query.rows,
      total: count || await this.database.medicineTable.count(),
      data: result
    });
  }

  static getGoodsList(query: RepositoryQuery) {
    return this.http.post('/Goods/newList', query);
  }
}
