import "core-js";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { Provider as MobxProvider } from 'mobx-react';
import appStore from './common/appStore';
import 'vconsole/dist/vconsole.min';
import VConsole from "vconsole";

if (process.env.NODE_ENV === 'development') {
  new VConsole();
}

ReactDOM.render(
  <MobxProvider store={appStore}>
    <App />
  </MobxProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
