import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import { InputItem, Toast } from 'antd-mobile';
import { PasswordService } from '../../service/password.service';
import './modify-password.scss';
import { PasswordForm } from '../../model';
import { Navigate } from '../../tools/navigate';

export default class ModifyPassword extends PureComponent {

  submitData: PasswordForm = {
    oldPwd: '',
    pwd: '',
    confirmPwd: ''
  };

  errorTip: PasswordForm = {
    oldPwd: '旧密码不能为空',
    pwd: '新密码不能为空',
    confirmPwd: '重复密码不能为空'
  }

  passwordChange(value: string, type: 'oldPwd' | 'pwd' | 'confirmPwd') {
    this.submitData[type] = value;
  }

  submit = async () => {
    if (!this.submitData.oldPwd) {
      Toast.fail('旧密码不能为空', 1.5);
    } else if (!this.submitData.pwd) {
      Toast.fail('新密码不能为空', 1.5);
    }  else if (this.submitData.confirmPwd !== this.submitData.pwd) {
      Toast.fail('重复密码和新密码不相同', 1.5);
    } else {
      await PasswordService.modifyPwd(this.submitData.oldPwd, this.submitData.confirmPwd);
      Navigate.route.goBack();
    }
  }

  render() {
    return (
      <div className="modify-password">
        <NavBar title="修改密码"></NavBar>
        <br/>
        <InputItem placeholder="请输入旧密码" type="password" onChange={(v) => this.passwordChange(v, 'oldPwd')} />
        <br/>
        <InputItem placeholder="请输入新密码" type="password" onChange={(v) => this.passwordChange(v, 'pwd')}  />
        <br/>
        <InputItem placeholder="再次输入新密码" type="password" onChange={(v) => this.passwordChange(v, 'confirmPwd')}  />
        <div className="pwdTip">提示：密码要满足6位以上，同时包含字母和数字</div>
        <div className="flex flexCenter">
          <button className="modifyPasswordBtton" onClick={this.submit}>提交修改</button>
        </div>
      </div>
    );
  }

}
