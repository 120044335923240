import { PureComponent } from "react";
import React from 'react';

export default class User extends PureComponent {
  render() {
    return (
      <div>
        This`s user
      </div>
    )
  }
}
