
import React, { PureComponent } from 'react'
import NavBar from '../../../components/navBar/nav-bar';
import { RepositoryQuery, ReposieoryItem } from '../../../model';
import { RepositoryService } from '../../../service/repository.service';
import storeImg from '../../../assets/img/repository/storeIcon.png';
import "./repository-result.scss";
import SearchBar from '../../../components/search-bar/search-bar';
import BottomRefresh from '../../../components/bottom-refresh/bottom-refresh';
import { Navigate } from '../../../tools/navigate';

export default class RepositoryResult extends PureComponent<any, any> {

  query: RepositoryQuery;

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      loading: false
    };
    this.query = {
      goods_name: '',
      page: 1,
      rows: 20,
      total: 0,
    };
  }
  toDetail(item:string) {
    Navigate.route.push(`/repositoryResultDetail/${item}`);
    //Navigate.route.push({pathname:string:'/repositoryResultDetail',query:{goods_code:item}});
  }
  componentDidMount() {
    this.getList();
  }

  setQueryName = (v: string) => {
    this.query.goods_name = v;
  }

  getList = async () => {
    this.setState({loading: true});
    const data = await RepositoryService.reposList(this.query);
    this.query.page = data.current_page;
    this.query.rows = +data.per_page;
    this.query.total = data.total;
    this.setState({loading: false});
    this.setState({
      dataList: [...this.state.dataList, ...data.data]
    });
  }

  pageSearch = () => {
    this.query.page++;
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.query.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  nameSearch = () => {
    this.query.page = 1;
    this.setState({dataList: []});
    this.getList();
  }

  repositoryList() {
    return this.state.dataList.map((item: ReposieoryItem, index: number) => {
      return <div className="item" key={index} onClick={this.toDetail.bind(this, item.goods_code)}>
        <div className="medName">
          <img src={storeImg} alt=""></img>
          <div className={+item.count === 0? 'red name': 'name'}>{item.goods_name + (item.name? `(${item.name})`: '')}</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">商品编码</div>
            <div className="paramValue">{item.goods_code}</div>
          </div>
          <div className="param">
            <div className="paramName">药品规格</div>
            <div className="paramValue">{item.goods_spec}</div>
          </div>
          <div className="param">
            <div className="paramName">整件包装</div>
            <div className="paramValue">{item.pack}</div>
          </div>
          <div className="param">
            <div className="paramName">业务库存</div>
            <div className="paramValue">{+item.count >= 0 ? item.count : 0}</div>
          </div>
          <div className="param">
            <div className="paramName">单位</div>
            <div className="paramValue">{item.unit}</div>
          </div>
          <div className="param">
            <div className="paramName">生产厂家</div>
            <div className="paramValue">{item.made_in}</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="repositoryResult" onScroll={this.scrollLoad}>
        <div className="repostHeader">
          <NavBar title="库存查询结果"></NavBar>
          <SearchBar placeholder="库存查询请输入名称" onChange={this.setQueryName} onSearch={this.nameSearch}></SearchBar>
        </div>
        <div className="reposReultList">
          {this.repositoryList()}
        </div>
        <BottomRefresh
          total={this.query.total}
          infactTotal={this.state.dataList.length}
          isLoading={this.state.loading}
          onSearch={this.pageSearch}></BottomRefresh>
      </div>
    )
  }
}
