import { Http } from "./core/httpForHome";
import { PageOption } from "../model";

export class LogisticsServiceForFlowInquiry {

  protected static http: Http = new Http();

  static getList(pageOption: PageOption, query: any) {
    return this.http.post('/Trade/getList', { ...pageOption, ...query});
  }

  static getListV2(pageOption: PageOption, query: any) {
    return this.http.post('/Trade/getListV2', { ...pageOption, ...query});
  }
}
export class LogisticsServiceForSales {

    protected static http: Http = new Http();
  
    static getList(pageOption: PageOption, query: any) {
      return this.http.post('/goods/statement', { ...pageOption, ...query});
    }
}
  