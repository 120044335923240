import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Toast } from 'antd-mobile';
import { AppConfig } from '../../App.config';
import { Storage } from './storage';
import { Tools } from '../../tools';
import qs from 'qs';
import { Navigate } from '../../tools/navigate';

export class Http {

  httpClient: AxiosInstance;

  get baseUrl(): string {
    return process.env.NODE_ENV === 'production'? AppConfig.productionURL: AppConfig.developmentURL;
  }

  constructor() {
    this.httpClient = Axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        // 'Accept': 'application/json;charset=UTF-8'
      },
      // transformRequest: data => {
      //   data.open_id = Storage.get('loginToken');
      //   data.tag = 'wx';
      //   return qs.stringify(data);
      // },
      // responseType: 'json',
    });
  }

  post<T = any>(url: string, data: any = {}, headers?: any) {
    return new Promise<T>((resolve, reject) => {
      data.open_id = Storage.get('loginToken');
      data.tag = 'wx';
      this.httpClient.post(url, qs.stringify(data), { headers}).then((response: AxiosResponse<IResponse<T>>) => {
        const result: IResponse<T> = this.parse(response.data);
        if (response.status === 200 && result.errcode === 0) {
          resolve(result.data);
          if(result.msg) { Toast.info(result.msg,2); }
        } else {
          this.unifyError(reject, url)(response.data);
        }
      }).catch(this.unifyError(reject, url));
    });
  }

  upload(url: string, data: any = {}, headers = {'Content-Type': 'multipart/form-data'}) {
    return new Promise<any>((resolve, reject) => {
      data.open_id = Storage.get('loginToken');
      data.tag = 'wx';
      const fromData = new FormData();
      for (const item of Object.keys(data)) {
        if (data[item] instanceof Array) {
          data[item].forEach((v: any, index: number) => {
            fromData.append(`${item}[${index}]`, v);
          });
        } else {
          fromData.append(item, data[item]);
        }
      }
      this.httpClient.post(url, fromData, { headers}).then((response: AxiosResponse<any>) => {
        const result = this.parse(response.data);
        if (response.status === 200 && result.errcode === 0) {
          resolve(result.data);
          if(result.msg) { Toast.info(result.msg); }
        } else {
          this.unifyError(reject, url)(response.data);
        }
      }).catch(this.unifyError(reject, url));
    });
  }

  get(url: string, data: any = {}) {
    return new Promise((resolve, reject) => {
      data.open_id = Storage.get('loginToken');
      data.tag = 'wx';
      const queryParams = Tools.objectToUrlString(data || {});
      this.httpClient.get(`${url}${queryParams? '?' + queryParams: ''}`).then((response: AxiosResponse<any>) => {
        if (response.status === 200) {
          resolve(this.parse(response.data));
        } else {
          this.unifyError(reject, url)(response.data);
        }
      }).catch(this.unifyError(reject, url));
    });
  }

  unifyError(reject: (error: any) => void, url?: string) {
    return (error: any) => {
      Toast.fail(error.msg, 2, undefined, false);
      if (error.errcode === 10001 && url !== '/wechat/isBind') {
        Navigate.route.replace('/login');
      }
      reject(error);
      console.log(error, url);
    };
  }

  parse(data: any) {
    return typeof data === 'string'? JSON.parse(data): data;
  }
}

interface IResponse<T> {
  errcode: number;
  msg: string;
  data: T;
}
