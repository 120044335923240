import React, { PureComponent, ChangeEvent } from 'react';
import bgImg from '../../assets/img/login-blue-bg.png';
import userBlueIcon from '../../assets/img/user-blue.png';
import userGrayIcon from '../../assets/img/user-gray.png';
import lockBlueIcon from '../../assets/img/lock-blue.png';
import lockGrayIcon from '../../assets/img/lock-gray.png';
import { Toast } from 'antd-mobile';
import './login.scss';
import { Navigate } from '../../tools/navigate';
import { LoginService } from '../../service/login.service';
import { inject, observer } from 'mobx-react';
// import Listener from '../../service/core/global.service';
import { action } from 'mobx';

@inject('store')
@observer
export class Login extends PureComponent<any, any> {

  constructor() {
    super(null);
    this.state = {
      password: '',
      account: '',
      pwdIconFocus: false,
      acIconFocus: false
    };
  }

  componentDidMount() {
    this.initListTime();
    console.log(this.props.store.orderListTime);
    // Listener.clearComponentCacheTrigger.next({name: 'orderList', disabled: true});
  }

  @action
  initListTime() {
    const { store } = this.props;
    store.orderListTime.startTime = null;
    store.orderListTime.endTime = null;
  }

  accountChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({account: event.target.value});
  }

  passwordChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({password: event.target.value});
  }

  inputFocus(field: 'pwd' | 'ac' | null) {
    if (field === 'pwd') {
      this.setState({
        pwdIconFocus: true,
        acIconFocus: false
      });
    } else if (field === 'ac') {
      this.setState({
        pwdIconFocus: false,
        acIconFocus: true
      });
    } else {
      this.setState({
        pwdIconFocus: false,
        acIconFocus: false
      });
    }
  }

  login = async () => {
    Toast.loading('登录中...');
    await LoginService.login(this.state.account, this.state.password);
    // Toast.loading('加载数据中...');
    // await GoodsService.getAllMedicineData();
    // await  CustomerService.getAllMedicineData()
    Toast.info('登入成功', 1.5);

    Navigate.route.replace('/home');
  }

  render() {
    return (
      <div className="login">
        <img src={bgImg} alt="bg" className="bg"></img>
        <div className="centerBlock">
          <div className="centerInput">
            <div className="input">
              <img className="inputIcon" src={this.state.acIconFocus? userBlueIcon: userGrayIcon} alt="user"></img>
              <input className="inputFrame" onChange={this.accountChange} onFocus={this.inputFocus.bind(this, 'ac')} onBlur={this.inputFocus.bind(this, null)} placeholder="请输入账号"></input>
            </div>
            <div className="input">
              <img className="inputIcon lockIcon" src={this.state.pwdIconFocus? lockBlueIcon: lockGrayIcon} alt="lock"></img>
              <input className="inputFrame" onChange={this.passwordChange} onFocus={this.inputFocus.bind(this, 'pwd')} onBlur={this.inputFocus.bind(this, null)} type="password" placeholder="请输入密码"></input>
            </div>
            <button className="loginButton" onClick={this.login}>立即登录</button>
          </div>
        </div>
      </div>
    )
  }
}
