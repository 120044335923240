//  ! globalService内的类全局实例只有一个
import { Subject } from 'rxjs';

class ListenerService {

  clearComponentCacheTrigger: Subject<{name: string, disabled: boolean}> = new Subject();

}

const Listener = new ListenerService();

export default Listener;
