
import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import { observer, inject } from 'mobx-react';
import { action, computed } from 'mobx';
import plusIcon from '../../assets/img/plus.png';
import deleteIcon from '../../assets/img/deleteIcon.png';
import { ImagePicker, Toast } from 'antd-mobile';
import { Navigate } from '../../tools/navigate';
import { Tools } from '../../tools';
import checked from '../../assets/img/checked.png';
import medicineIcon from '../../assets/img/my-order/medicine.png';
import './do-business.scss'

@inject('store')
@observer
export default class CreateOrder extends PureComponent<any, any> {

  orderData = {
    medicineList: [],
    cilent: null,
    remark: '',
    imgList: [],
    imgListb: [],
    imgListc: [],
    imgListd: [],
    imgListe: [],
    imgListf: [],
    imgListg: [],
    imgListh: [],
    imgListi: [],
    imgListj: [],
  };

  constructor(props: any) {
    super(props);
    this.state = {
      files: [],
      files2: [],
      files3: [],
      files4: [],
      files5: [],
      files6: [],
      files7: [],
      files8: [],
      files9: [],
      files10: [],
      medicineList: [],
      remark: '',
      client: null,
      checkedAll: true
    };
  }

  async componentDidMount() {
    const { store } = this.props;
    this.createOrderData.medicineList.forEach((item: any) => {
      item.checked = true;
      // item.amount = 1;
    });
    const medicineList = this.loadingCacheList();
    this.setState({
      medicineList,
      files: store.createOrder.imgList,
      files2: store.createOrder.imgListb,
      files3: store.createOrder.imgListc,
      files4: store.createOrder.imgListd,
      files5: store.createOrder.imgListe,
      files6: store.createOrder.imgListf,
      files7: store.createOrder.imgListg,
      files8: store.createOrder.imgListh,
      files9: store.createOrder.imgListi,
      files10: store.createOrder.imgListj,
      remark: store.createOrder.remark,
      client: store.createOrder.cilent,
    });
    this.setMedicineList(medicineList);
  }
  componentWillUnmount() {
  }
  @computed get createOrderData() {
    return this.props.store.createOrder;
  }

  @action
  setMedicineList(list: any[]) {
    this.createOrderData.medicineList = list;
  }
  loadingCacheList() {
    const source = this.createOrderData.medicineList;
    const cache = this.props.store.checkedMedicines;
    cache.forEach((item: any) => {
      if(item.price) {
        item.price = +item.price;
      }
    });
    const data: any[] = [];
    source.forEach((item: any) => {
      // const findItem = cache.find((v: any) => v.id === item.id);
      // if (findItem) {
      //   item.amount = findItem.amount? findItem.amount: (item.amount + 1);
      // }
      data.push(item);
    });
    // 把不在原列表的数据加到状态列表里
    cache.forEach((item: any) => {
      // if (!source.find((v: any) => item.id === v.id)) {
      // }
      if (!item.amount) {
        item.amount = 1;
      }
      data.push(item);
    });
    return data;
  }
  @action
  imgChange = (files: any) => {
    this.setState({
      files,
    });
    this.createOrderData.imgList = files;
  }
  imgChange2 = (files2: any) => {
    this.setState({
      files2,
    });
    this.createOrderData.imgListb = files2;
  }
  imgChange3 = (files3: any) => {
    this.setState({
      files3,
    });
    this.createOrderData.imgListc = files3;
  }
  imgChange4 = (files4: any) => {
    this.setState({
      files4,
    });
    this.createOrderData.imgListd = files4;
  }
  imgChange5 = (files5: any) => {
    this.setState({
      files5,
    });
    this.createOrderData.imgListe = files5;
  }
  imgChange6 = (files6: any) => {
    this.setState({
      files6,
    });
    this.createOrderData.imgListf = files6;
  }
  imgChange7 = (files7: any) => {
    this.setState({
      files7,
    });
    this.createOrderData.imgListg = files7;
  }
  imgChange8 = (files8: any) => {
    this.setState({
      files8,
    });
    this.createOrderData.imgListh = files8;
  }
  imgChange9 = (files9: any) => {
    this.setState({
      files9,
    });
    this.createOrderData.imgListi = files9;
  }
  imgChange10 = (files10: any) => {
    this.setState({
      files10,
    });
    this.createOrderData.imgListj = files10;
  }
  toSelectGoods = () => {
    if(this.state.medicineList.length>=1){
      Toast.info('一次仅支持添加一件商品，请删除商品后再试');
    }else{
      Navigate.route.push('/selectMedicine4business');
    }
  }
  @action
  deleteGoods = () => {
    const list: any[] = [];
    this.props.store.createOrder.medicineList = list;
    this.setState({
      medicineList: list,
      checkedAll: false
    });
    Toast.info('删除成功');
  }
  @action
  checkedmedicine = (index: number) => {
  }

  

  

  

  @action
  submit = async () => {
    let msg = '';
    if (!this.createOrderData.medicineList.length) { msg = '请选择商品';}
    if (this.createOrderData.imgList.length == 0) { msg = '请上传药品生产或者进口批准证明文件复印件及药品补充申请批件复印件';}
    if (this.createOrderData.imgListb == undefined || this.createOrderData.imgListb.length == 0) { msg = '请上传药品质量标准复印件';}
    if (this.createOrderData.imgListc == undefined || this.createOrderData.imgListc.length == 0) { msg = '请上传药品说明书、包装、标签备案件复印件';}
    if (this.createOrderData.imgListd == undefined || this.createOrderData.imgListd.length == 0) { msg = '请上传药品样盒、说明书实样';}
    if (this.createOrderData.imgListe == undefined || this.createOrderData.imgListe.length == 0) { msg = '请上传药品价格确认表或物价单复印件';}
    if (this.createOrderData.imgListf == undefined || this.createOrderData.imgListf.length == 0) { msg = '请上传药品检验报告书';}
    if (this.createOrderData.imgListg == undefined || this.createOrderData.imgListg.length == 0) { msg = '请上传药品生产企业的营业执照复印件药品生产许可证复印件';}
    if (this.createOrderData.imgListh == undefined || this.createOrderData.imgListh.length == 0) { msg = '请上传在有效期内的与品种剂型相适应的GMP认证证书复印件';}
    if (this.createOrderData.imgListi == undefined || this.createOrderData.imgListi.length == 0) { msg = '请上传胶囊剂药品需提供合格的铬检验报告单复印件';}
    if (this.createOrderData.imgListj == undefined || this.createOrderData.imgListj.length == 0) { msg = '请上传OTC产品提供《非处方要药品审核登记证书》复印件';}
    if(msg) {
      Toast.offline(msg);
    } else {
      Toast.loading('提交证书资料中...', 0);
      const url = this.createOrderData.imgList.filter((img: any) => /^(http|https).*/.test(img.url)).map((item: any) => item.url);
      const base64 = this.createOrderData.imgList.filter((img: any) => !/^(http|https).*/.test(img.url)).map((item: any) => item.url);
      const data = {
        goodsArr: JSON.stringify(this.createOrderData.medicineList[0].goods_code),
        img: base64.map((img: string) => Tools.base64toBlob(img)),
        url
      };
      console.log(data.goodsArr)
      Toast.hide(); 
    }
  }
  medicineList = () => {
    return this.state.medicineList.map((item: any, index: number) => {
      return <div className="medicineItem" key={index}>
      <div className="top">
          <img onClick={this.checkedmedicine.bind(this, index)} className="checkbox" src={checked} alt=""></img>
        <div className="medicineLable">
        </div>
          <img className="reposIcon" src={medicineIcon} alt=""></img>
      </div>
      <div className="bottom">
        <div className="price" style={{fontSize: '0.4rem'}}>
          {item.goods_name}
          {item.name? `(${item.name})`: ''}
        </div>
      </div>
    </div>
    })
  }
  imageFiles = () => {
    var { files } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品生产或者进口批准证明文件复印件及药品补充申请批件复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files}
                  onChange={this.imgChange}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles2 = () => {
    var { files2 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品质量标准复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files2}
                  onChange={this.imgChange2}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles3 = () => {
    var { files3 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品说明书、包装、标签备案件复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files3}
                  onChange={this.imgChange3}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles4 = () => {
    var { files4 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品样盒、说明书实样
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files4}
                  onChange={this.imgChange4}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles5 = () => {
    var { files5 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品价格确认表或物价单复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files5}
                  onChange={this.imgChange5}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles6 = () => {
    var { files6 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品检验报告书
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files6}
                  onChange={this.imgChange6}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles7 = () => {
    var { files7 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传药品生产企业的营业执照复印件药品生产许可证复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files7}
                  onChange={this.imgChange7}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles8 = () => {
    var { files8 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传在有效期内的与品种剂型相适应的GMP认证证书复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files8}
                  onChange={this.imgChange8}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles9 = () => {
    var { files9 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传胶囊剂药品需提供合格的铬检验报告单复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files9}
                  onChange={this.imgChange9}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  imageFiles10 = () => {
    var { files10 } = this.state;
    return <div className="orderBlock">
            <div className="name">
              上传OTC产品提供《非处方要药品审核登记证书》复印件
            </div>
            <div className="content">
              <div className="imagePicker">
                <ImagePicker
                  files={files10}
                  onChange={this.imgChange10}
                  onImageClick={(index, fs) => console.log(index, fs)}
                />
              </div>
            </div>
          </div>
  }
  render() {
    return (
      <div className="createOrder doBusiness">
        <div className="header">
          <NavBar title={'首次营业'}></NavBar>
        </div>
        <div className="orderBody">
          <div className="orderBlock">
            <div className="content">
              { this.medicineList() }
              <div className="addGoods flex flexCenter">
                <div className="flex flexCenter" onClick={this.toSelectGoods}>
                  <img className="plusIcon" src={plusIcon} alt=""></img>
                  添加商品
                </div>
                <div className="deleteIcon flexCenter flex" onClick={this.deleteGoods}>
                  <img className="plusIcon" src={deleteIcon} alt=""></img>
                  删除商品
                </div>
              </div>
            </div>
          </div>
          { this.imageFiles() }
          { this.imageFiles2() }
          { this.imageFiles3() }
          { this.imageFiles4() }
          { this.imageFiles5() }
          { this.imageFiles6() }
          { this.imageFiles7() }
          { this.imageFiles8() }
          { this.imageFiles9() }
          { this.imageFiles10() }
        </div>
        <div className="createOrderSubmit">
          <div className="left">
          </div>
          <div className="right">
            <div className="submit flex flexCenter" onClick={this.submit}>提交</div>
          </div>
        </div>
      </div>
    )
  }
}
