import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './purchase-notice.scss';
import TimePicker from '../../components/time-picker/time-picker';
import storeImg from '../../assets/img/repository/storeIcon.png';
import moment from 'moment';
import { NoticeService } from '../../service/notice.service';
import { PageOption } from '../../model';
import NoResult from '../../components/no-result/no-result';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
// import { Modal, List, Button, Toast } from 'antd-mobile';
import { Navigate } from '../../tools/navigate';
// import { Tools } from '../../tools';

@observer
export default class PurchaseNotice extends PureComponent<any, any> {

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  @observable noResult: boolean = false;

  query: {start_time: string, end_time: string};

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      currentUrl: ''
    };
    this.query = {
      start_time: this.formatDate(moment(new Date()).hour(0).minute(0)),
      end_time: this.formatDate(moment(new Date()).hour(23).minute(59))
    };
    this.getList();
  }

  async getList() {
    this.noResult = false;
    const data = await NoticeService.getList(this.pageOption, this.query);
    this.pageOption.page = data.current_page;
    this.pageOption.rows = +data.per_page;
    this.pageOption.total = +data.total;
    this.setState({
      dataList: [...this.state.dataList, ...data.data]
    });
    if (!this.state.dataList.length) {
      this.noResult = true;
    }
  }

  timeSearch = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.resetSearch();
  }

  resetSearch() {
    this.pageOption.page = 1;
    this.setState({
      dataList: []
    }, () => {
      this.getList();
    });
  }

  pageSearch() {
    this.pageOption.page++;
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.pageOption.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  toDetail(item: any) {
    Navigate.route.push(`/noticeDetail/${item.id}`);
  }

  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  copyUrl = () => {

  }

  inquiryList() {
    return this.state.dataList.map((item: any, index: number) => {
      return <div className="item" key={index} onClick={this.toDetail.bind(this, item)}>
        <div className={!item.is_read ? "medName isRead" : "medName"}>
          <img src={storeImg} alt=""></img>
          <div className="name">{item.data[0].common_name}({item.data[0].goods_name})</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">需求类型</div>
            <div className="paramValue">{item.type}</div>
          </div>
          <div className="param">
            <div className="paramName">联系人</div>
            <div className="paramValue">{item.contacts}</div>
          </div>
          <div className="param">
            <div className="paramName">单位名称</div>
            <div className="paramValue">{item.business_name}</div>
          </div>
          <div className="param">
            <div className="paramName">商品名称</div>
            <div className="paramValue">{item.data[0].common_name}({item.data[0].goods_name})</div>
          </div>
          <div className="param">
            <div className="paramName">规格</div>
            <div className="paramValue">{item.data[0].goods_spec}</div>
          </div>
          <div className="param">
            <div className="paramName">生产厂家</div>
            <div className="paramValue">{item.data[0].manufacturer}</div>
          </div>
          <div className="param">
            <div className="paramName">商品种类</div>
            <div className="paramValue">{item.data.length}</div>
          </div>
          <div className="param">
            <div className="paramName">时间</div>
            <div className="paramValue">{item.date}</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="purchaseNotice" onScroll={this.scrollLoad} style={{paddingTop: "0"}}>
        <div className="header">
          <NavBar title="采购通知"></NavBar>
          <TimePicker onSearch={this.timeSearch}  startTime={this.query.start_time} endTime={this.query.end_time}></TimePicker>
        </div>
        <div className="purchaseNoticeList">
          { this.inquiryList() }
          { this.noResult && <NoResult></NoResult>}
        </div>
      </div>
    )
  }
}
