import React, { PureComponent } from 'react'
import NavBar from '../../../components/navBar/nav-bar';
import { IssueService } from '../../../service/issue.service';
import './issue-detail.scss';
import issueIcon from '../../../assets/img/issuse-icon.png';

export default class IssueDetail extends PureComponent<any, any> {

  issueID: number;

  constructor(props: any) {
    super(props);
    this.issueID = +this.props.match.params.issueID;
    this.state = {
      detail: {}
    };
  }

  componentDidMount() {
    this.getDetail();
  }

  async getDetail() {
    const detail = await IssueService.detail(this.issueID);
    this.setState({
      detail: detail[0]
    });
  }

  render() {
    return (
      <div className="issueDetail">
        <NavBar title="问题详情"></NavBar>
        <div className="issueDetailTitle"><img src={issueIcon} alt=""></img>{this.state.detail.title}</div>
        <section className="issueDetailContent" dangerouslySetInnerHTML={{__html: this.state.detail.content}}></section>
      </div>
    )
  }
}
