import React, { PureComponent } from 'react'
import { PageOption } from '../../../model';
import NavBar from '../../../components/navBar/nav-bar';
import { LogisticsService } from '../../../service/loginstics.servece2';
import clientIcon from '../../../assets/img/cilent-store.png'
import phoneIcon from '../../../assets/img/phone.png';
import '../report-dedail/report-dedail.scss';
import medicineIcon from '../../../assets/img/my-order/medicine.png';
import { observable } from 'mobx';
import NoResult from '../../../components/no-result/no-result';
import BottomRefresh from '../../../components/bottom-refresh/bottom-refresh';

export default class FlowInquiry extends PureComponent<any, any> {
    @observable isLoading: boolean = false;
    @observable noResult: boolean = false;
    constructor(props: any) {
        super(props);
        this.state = {
            query: {'start_time':this.props.match.params.start_time,'end_time':this.props.match.params.end_time,'admin_id': this.props.match.params.admin_id},
            dataList1: [],
            stateType: [
                {title: '未开票'},
                {title: '已开票'},
                {title: '已发货'},
                {title: '已撤销'},
                {title: '已驳回'}
          ]
        };
    }
    pageOption: PageOption = {
        page: 1,
        rows: 10,
        total: 0
    };
    scrollLoad = ({target}: any) => {
      if (this.state.dataList1.length >= this.pageOption.total) { return ; }
      if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
        this.pageSearch();
      }
    }
    pageSearch() {
      if (this.isLoading) { return ;}
      this.pageOption.page++;
      this.getList();
    }
    async getList() {
        this.noResult = false;
        const data = await LogisticsService.getList(this.pageOption, this.state.query);
        for(let i = 0; i<data.data.length ; i++){
            data.data[i].state = this.state.stateType[data.data[i].state].title
        }
        this.pageOption.page = data.current_page;
        this.pageOption.rows = +data.per_page;
        this.pageOption.total = +data.total;
        const lastResult = [...this.state.dataList1, ...data.data];
        this.setState({
          dataList1: [...this.state.dataList1, ...data.data]
        });
        if (!lastResult.length) {
          this.noResult = true;
        }
        this.setState({
          dataList1: lastResult
        });
        this.isLoading = false;
    } 
  render() {
    return (
      <div onScroll={this.scrollLoad} style={{overflowY: 'scroll',height: '100vh'}}>
          <div className="header">
            <NavBar title="订单详情"></NavBar>
          </div>
          <div style={{background:"#f4f4f4"}}>
            { this.inquiryList() }
            {this.noResult && <NoResult></NoResult>}
            {
          !this.noResult && <BottomRefresh
          total={this.pageOption.total}
          infactTotal={this.state.dataList1.length}
          isLoading={this.isLoading}
          onSearch={this.pageSearch}></BottomRefresh>
        }
          </div>
          
          
      </div>
    )
  }

  inquiryList() {
    return this.state.dataList1.map((item: any, index: number) => {
      return <div className="item orderDetail" key={index}>
        <div className="orderItem">
          <div className="time-state">
            <div className="time">下单时间：</div>
            <div className="state">{item.create_time}</div>
            <div className="stateType">
              {item.state}
            </div>
          </div>
          <div className="storeName">
            <img alt='' src={clientIcon} className="storeIcon"></img>
            <label>{item.business_name}</label>
          </div>
          <div className="storeName">
            <div className="storeName zeroMargin">
              <label className="storeTitle">业务员：</label>
              <label className="storeValueCol">{item.sales_man}</label>
              <a href={'tel:'+ item.sales_man_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
            </div>
            { (item.state === "已开票" || item.state === "已发货")?<div className="storeName zeroMargin">
              <label className="storeTitle">开票员：</label>
              <label className="storeValueCol">{item.invoice_clerk}</label>
              <a href={'tel:'+ item.invoice_clerk_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
            </div>: ''}
          </div>
          {
            item.state === "已发货"? <div className="storeName">
              <label>物流类型：</label>
              <label className="red">{(item.shipping_name === '客户自提' || item.shipping_name === '自主配送')? item.shipping_name: '物流跟踪'}</label>
            </div>: ''
          }
          
          {
            item.reason? <div className="storeName">
            <label>驳回原因：</label>
            <label className="red">{item.reason}</label>
          </div>: ''
          }
           {
            item.order_goods.map(function(item1: any,number: number){
                return (
                    <div className={item1.checked? 'icon-detail icon-detail-gray': 'icon-detail'} key={item1.id}>
                        <img className="medicineIcon" src={medicineIcon} alt=""></img>
                        <div className="detail">
                        <div>{item1.goods_code}</div>
                        <div>{item1.goods_name}</div>
                        <div>￥{item1.price}</div>
                        <div className="">{item1.company}</div>
                        </div>
                        <div className="rightData flex">
                        <div className="simplyPrice flex">
                            <label>
                            {item1.goods_spec}
                            </label>
                            <label>
                            *{item1.amount}{item.unit}
                            </label>
                        </div>
                        <div className="red simplyComputed">
                            小计：{(+item1.price * +item1.amount).toFixed(2)}元
                        </div>
                        </div>
                    </div>
                );
            })
           }
          <div className="storeName">
            <label>订单备注：</label>
            <label>{item.description}</label>
          </div>
          <div className="storeName storeNameBlock">
            <label>订单图片：</label>
            {item.images && item.images.map((item: string, i: number) => <img alt="" className="remarkImg" src={item} ></img>)}
          </div>
          <div className="price-query">
            <div className='amount red'>总价：￥{item.total}元</div>
            <div className="btns">
              {/* { this.judgeBtnByRole(item) }
              { (item.state === 2 && !(item.shipping_name === '客户自提' || item.shipping_name === '自主配送'))? <button className="orderBtn" onClick={this.toLogistics.bind(this, item.Id)}>查看物流</button>: ''} */}
            </div>
          </div>
         
        </div>
      </div>
    });
  }
  async componentWillMount() {
    
    this.getList();
  }
}