import Dexie from 'dexie';
import { IMedicine, ICustomer } from '../../model';

export class AppDataBase extends Dexie {

  medicineTable: Dexie.Table<IMedicine, number>;

  customerTable: Dexie.Table<ICustomer, number>;

  constructor() {
    super('appDatabase');
    this.version(1).stores({
      medicineTable: '++id, createTime, inventory, company, goods_code, goods_spec, rate, rate_price, sell_ctrl, unit, market_price, pinyin_code, describe, made_in',
      customerTable: '++id, address, name, phone, pinyin_code'
    });
    this.medicineTable = this.table('medicineTable');
    this.customerTable = this.table('customerTable');
  }

  async clearTable() {
    await this.medicineTable.clear();
    await this.customerTable.clear();
  }
}
