export class Storage {

  protected static readonly prefix: string = 'medicine_';

  static set(field: string, data: any) {
    window.localStorage.setItem(this.prefix + field, JSON.stringify(data));
  }

  static get(field: string) {
    const value = window.localStorage.getItem(this.prefix + field);
    return value;
  }

  static getParse(field: string) {
    const value = window.localStorage.getItem(this.prefix + field);
    try {
      return value? JSON.parse(value): '';
    } catch (error) {
      return '';
    }
  }

  static remove(field: string) {
    window.localStorage.removeItem(this.prefix + field);
  }

  static clear() {
    window.localStorage.clear();
  }
}