import React, { PureComponent, createRef, RefObject, Props } from 'react';
import './home.scss';
import { Carousel, Modal, Toast } from 'antd-mobile';
import moment from 'moment';
import createOrder from '../../assets/img/home/createOrder.png';
import { PageOption } from '../../model';
// import feedback from '../../assets/img/home/feedback.png';
import flow from '../../assets/img/home/flow.png';
import inbound from '../../assets/img/home/inbound.png';
import issue from '../../assets/img/home/issue.png';
import order from '../../assets/img/home/myOrder.png';
import stroage from '../../assets/img/home/storage.png';
import avatarImg from '../../assets/img/home/avatar.png';
import revise from '../../assets/img/home/revisePassword.png';
import homeBg from '../../assets/img/home/homeBgImg.png';
import notice from '../../assets/img/home/notice.png';
import commision from '../../assets/img/home/commisionList.png';
// import reportStatistics from '../../assets/img/home/reportStatistics.png'
// import salesStatistics from '../../assets/img/home/salesStatistics.png'
// import mobileBanking from '../../assets/img/home/mobileBanking.png'
import { Navigate } from '../../tools/navigate';
import { HomeService } from '../../service/home.service';
import { Storage } from '../../service/core/storage';
import { LoginService } from '../../service/login.service';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import { LogisticsServiceForFlowInquiry,LogisticsServiceForSales } from '../../service/loginjump.service'

@inject('store')
@observer
export default class Home extends PureComponent<any, any> {
  query: {start_time: string, end_time: string};
  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  carouselInstance: RefObject<Carousel>;

  buttons = [
    { icon: createOrder, name: '我要下单', page: '/createOrder' },
    { icon: stroage, name: '库存查询', page: '/repositoryResult'},
    { icon: order, name: '我的订单', page: '/orderList' },
    // { icon: feedback, name: '意见反馈', page: '/feedback' },
    { icon: issue, name: '常见问题', page: '/issue' },
    { icon: inbound, name: '入库查询', page: '/inboundInquiry' },
    { icon: flow, name: '流向查询', page: '/flowInquiry' },
    { icon: notice, name: '采购通知', page: '/purchaseNotice' },
    { icon: revise, name: '修改密码', page: '/modifyPassword'},
    { icon: commision, name: '提成列表', page: '/commisionList'},
    // { icon: reportStatistics, name: '下单统计', page: '/reportStatistics'},
    // { icon: salesStatistics, name: '销售统计', page: '/salesStatistics'},
    // { icon: mobileBanking ,name:'首次营业' , page: '/dobusiness'}
  ];

  yewuButtons = [
    { icon: createOrder, name: '我要下单', page: '/createOrder' },
    { icon: stroage, name: '库存查询', page: '/repositoryResult'},
    { icon: order, name: '我的订单', page: '/orderList' },
    // { icon: feedback, name: '意见反馈', page: '/feedback' },
    { icon: issue, name: '常见问题', page: '/issue' },
    { icon: notice, name: '采购通知', page: '/purchaseNotice' },
    { icon: revise, name: '修改密码', page: '/modifyPassword'},
    { icon: commision, name: '提成列表', page: '/commisionList'},
    // { icon: reportStatistics, name: '下单统计', page: '/reportStatistics'},
    // { icon: salesStatistics, name: '销售统计', page: '/salesStatistics'},
    // { icon: mobileBanking ,name:'首次营业' , page: '/dobusiness'}
  ];

  constructor(props: Props<any>) {
    super(props);
    this.state = {
      swiperImgs: []
    };
    this.query = {
      start_time: this.formatDate(moment(new Date()).hour(0).minute(0)),
      end_time: this.formatDate(moment(new Date()).hour(23).minute(59))
    };
    this.carouselInstance = createRef();
  }

  async componentWillMount() {
    const data = await HomeService.getHomeData();

    if (data.role_type === 4) {
      this.buttons.push({ icon: commision, name: '业务员列表', page: '/salesmanList' });
    }

    this.setState({
      swiperImgs: data.carousel,
      userName: data.username,
      cbDay: data.total.cb_day,
      monthData: data.total.month,
      todayData: data.total.today,
      priceDay: data.total.tc_price_day,
      priceMonth: data.total.tc_price_month,
      role: data.role,
      roleType: data.role_type
    });
    Storage.set('homeData', data);
    Storage.set('role', data.role);
    Storage.set('roleType', data.role_type);
    Storage.set('adminID', data.admin_id);
    this.resetOrderStore();
  }

  updateCarousel = () => {
    if (this.carouselInstance.current) {
      this.carouselInstance.current.onChange(1);
      // this.carouselInstance.current.forceUpdate();
      this.forceUpdate();
    }
  }
  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }
  async route(url: string) {
    if(url && url === '/flowInquiry') {
      const data = await LogisticsServiceForFlowInquiry.getListV2(this.pageOption, this.query)
      if(data.data.errcode === 0){
        Storage.remove('flowInquery');
        Navigate.route.push(url)
      }
    }else if(url && url === '/salesStatistics'){
      const data = await LogisticsServiceForSales.getList(this.pageOption, this.query)
      if(data.data.errcode === 0){Navigate.route.push(url)}
    }else{
      Navigate.route.push(url)
    }
  }

  logout = () => {
    const alertInstance = Modal.alert('退出登录', '确定要退出登录吗？', [
      { text: '取消', onPress: () => alertInstance.close() },
      { text: '确定', onPress: async () => {
        Toast.loading('正在登出...');
        await LoginService.logout();
        Storage.clear();
        Toast.hide();
        Navigate.route.replace('/login');
       }
      },
    ]);
  }

  @action
  resetOrderStore() {
    const { store } = this.props;
    store.createOrder =  {
      id: null,
      medicineList: [],
      cilent: null,
      remark: '',
      imgList: [],
    };
    store.checkedMedicines = [];
  }

  render() {
    return (
      <div className="home">
        <img alt="" src={homeBg} className="homeBg"></img>
        <div className="userInfo">
          <img alt="" className="avatar" src={avatarImg}></img>
          <div className="baseInfo">
            <div className="role">{this.state.role}</div>
            <div className="userName">{this.state.userName}</div>
          </div>
          <div className="logout" onClick={this.logout}>退出登录</div>
        </div>
        <div className="salesVolume">
          <div className="volume1">
            <div className="volumeName">
              本日总成本（元）
            </div>
            <div className="volumeValue">{this.state.cbDay}</div>
          </div>
          <div className="volume">
            <div className="volumeName">
              本日销售额（元）
            </div>
            <div className="volumeValue">{this.state.todayData}</div>
          </div>
          <div className="volume">
            <div className="volumeName">
              本月销售额（元）
            </div>
            <div className="volumeValue">{this.state.monthData}</div>
          </div>
          <div className="volume">
            <div className="volumeName">
              日提成（元）
            </div>
            <div className="volumeValue">{this.state.priceDay}</div>
          </div>
          <div className="volume">
            <div className="volumeName">
              月提成（元）
            </div>
            <div className="volumeValue">{this.state.priceMonth}</div>
          </div>
        </div>
        {
          (this.state.swiperImgs && this.state.swiperImgs.length)?
            <Carousel autoplay
              infinite
              autoplayInterval={3000}
              className="swiper"
              ref={this.carouselInstance}
            >
            {this.state.swiperImgs.map((val: any,index: number) => (
                <a href={val.url} key={index}>
                <img
                  src={val.img}
                  alt=""
                  style={{ width: '100%', verticalAlign: 'top', height: '100%' }}
                  onLoad={this.updateCarousel}
                />
              </a>
            ))}
          </Carousel>: ''
        }
        <div className="routeIcons">
          {
            (this.state.roleType === 1)?
            this.yewuButtons.map((item, index) => {
              return <div key={index} className="routeIcon" onClick={this.route.bind(this, item.page)}>
              <img alt="" src={item.icon}></img>
              <label>{item.name}</label>
            </div>;
            }) :
            this.buttons.map((item, index) => {
              return <div key={index} className="routeIcon" onClick={this.route.bind(this, item.page)}>
                <img alt="" src={item.icon}></img>
                <label>{item.name}</label>
              </div>;
            })

          }
        </div>
      </div>
    );
  }
}
