import React, { PureComponent } from 'react'
import { ActivityIndicator } from 'antd-mobile';
import './bottom-refresh.scss';

export default class BottomRefresh extends PureComponent<any, any> {

  onSearch = () => {
    if (this.props.onSearch) {
      this.props.onSearch();
    }
  }

  render() {
    return (
      <div className="bottomRefresh">
        <div className="loading">
          {/* {this.props.isLoading  && <ActivityIndicator animating />}
          {this.props.isLoading  && <label className="loadingLable">加载中...</label>} */}
          {this.props.isLoading? <div className="loadingTip flex flexCenter">
          <ActivityIndicator animating />
          <label className="loadingLable">加载中...</label>
          </div>: '' }
          {
           !this.props.isLoading && <div className="loadingTip">
            {this.props.total > this.props.infactTotal && <div className="loadMore" onClick={this.onSearch}>点击加载</div>}
            {this.props.total <= this.props.infactTotal && <label>没有更多了</label>}
          </div>
          }
        </div>
      </div>
    )
  }
}
