import { Http } from "./core/http";

export class PasswordService {

  private static http: Http = new Http();

  static modifyPwd(old_pwd: string, new_pwd: string ) {
    return this.http.post('/wechat/editPwd', { old_pwd, new_pwd });
  }
}
