import React, { PureComponent } from 'react'
import noResult from '../../assets/img/noResult.png';
import './no-result.scss';

export default class NoResult extends PureComponent<any, any> {
  render() {
    return (
      <div className="noResult flex flexCenter" style={{paddingTop: this.props.paddingTop}}>
        <img src={noResult} alt="没有搜索结果"></img>
        <div className="noResultTip">没有搜索结果</div>
      </div>
    )
  }
}
