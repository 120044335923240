import React, { PureComponent } from 'react'
import './logistics-popup.scss';
import bgImg from '../../assets/img/home/homeBgImg.png';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { LogisticsService } from '../../service/logistics.service';
import { InputItem } from 'antd-mobile';

@observer
export default class LogisticsPopup extends PureComponent<any, any> {

  orderID: string = '';

  logisticsList: any[] = [];

  logisticsName: string = '';

  @observable expressId: string = '';

  @observable expressName: string  = '';

  @observable logisticsListCache: any[] = [];

  @observable isVisible: boolean = false;

  @observable logisticsListVisible: boolean = false;

  constructor(props: any) {
    super(props);
    LogisticsService.list().then((data: any) => {
      this.logisticsList = data;
      this.logisticsListCache = data;
    });
  }

  componentDidMount() {
    
  }

  open = (orderID: string) => {
    this.orderID = orderID;
    this.isVisible = true;
    this.expressName = '';
    this.expressId = '';
  }

  close = () => {
    this.isVisible = false;
  }

  submit = async () => {
    await LogisticsService.consignment(this.expressName, this.expressId, this.orderID);
    this.close();
    if(this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  expressNameChange = (event: any) => {
    if (event.target) {
      this.expressName = event.target.value;
    }
  }

  expressIdChange = (event: any) => {
     if (event.target) {
       this.expressId = event.target.value;
     }
  }

  openLogisticsList = () => {
    this.logisticsListVisible = true;
    this.logisticsListCache = JSON.parse(JSON.stringify(this.logisticsList));
  }

  closelogistics = () => {
    this.logisticsListVisible = false;
  }

  selectLogistics(item: any) {
    this.expressName = item.shipping_name;
    this.closelogistics();
  }

  inputCompany = (event: any) => {
    const value = event.target.value
    const data: any[] = [];
    this.logisticsList.forEach(item => {
      if (item.shipping_name.includes(value)) {
        data.push(item)
      }
    });
    this.logisticsListCache = data;
  }

  render() {
    return (
      <div className="logisticsPopup">
        {this.isVisible? <div className="bg flex flexCenter">
          <div className="logisticsContent flex">
            <img src={bgImg} className="bgImg" alt=""width="100%" ></img>
            <div className="Linput">
              &emsp;快递名称<br />
              <input placeholder="快递名" value={this.expressName} readOnly onClick={this.openLogisticsList}></input>
            </div>
            <br/>
            <div className='Linput'>
              &emsp;物流单号<br/>
              <input placeholder="快递单号" value={this.expressId} onChange={this.expressIdChange}></input>
            </div>
            <div className="btns">
              <label onClick={this.close}>
                取消
              </label>
              <label onClick={this.submit}>
                确定
              </label>
            </div>
          </div>
        </div>: ''}
        <div className="logisticsList" style={{display: this.logisticsListVisible? 'block': 'none' }}>
          <div className="blackbg" onClick={this.closelogistics}></div>
          <div className="list">
            <InputItem
              clear
              placeholder="输入物流公司名"
              autoFocus
              onInput={this.inputCompany}
            ></InputItem>
            {
              this.logisticsListCache.map((item: any, index: number) => {
                return <div className="logisticsItem flex flexCenter" key={index} onClick={this.selectLogistics.bind(this, item)}>
                  { item.shipping_name }
                </div>
              })
            }
          </div>
        </div>
      </div>
    )
  }
}
