import React, { PureComponent } from 'react';
import './loading-page.scss';
import { Tools } from '../../tools';
import { Http } from '../../service/core/http';
import { Storage } from '../../service/core/storage';
import { Navigate } from '../../tools/navigate';
import { LoginService } from '../../service/login.service';
import { Toast } from 'antd-mobile';

export class LoadingPage extends PureComponent {

  http: Http = new Http();

  constructor(props: any) {
    super(props);
    Storage.clear();
  }

  componentDidMount() {
    this.getCode();
  }
  async getCode() {
    const param = Tools.parseUrl();
    if (!param.open_id) {
      window.location.href = `${this.http.baseUrl}/wechat/getOpenId?url=${encodeURIComponent(window.location.href)}`;
    } else {
      Storage.set('loginToken', param.open_id);
      try {
        await LoginService.isLogin();
        Navigate.route.push('/home');
        // Toast.loading('加载数据中...');
        // await GoodsService.getAllMedicineData();
        // await CustomerService.getAllMedicineData();
        Toast.hide();
      } catch (error) {
        Navigate.route.push('/login');
      }
    }
  }

  render() {
    return (
      <div className="content">
        <div className="container animation-4">
          <div className="shape shape1"></div>
          <div className="shape shape2"></div>
          <div className="shape shape3"></div>
          <div className="shape shape4"></div>
        </div>
      </div>
    )
  }
}
