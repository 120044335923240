import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Toast } from 'antd-mobile';
import { AppConfig } from '../../App.config';
import { Storage } from './storage';
import qs from 'qs';
import { Navigate } from '../../tools/navigate';

export class Http {

  httpClient: AxiosInstance;

  get baseUrl(): string {
    return process.env.NODE_ENV === 'production'? AppConfig.productionURL: AppConfig.developmentURL;
  }

  constructor() {
    this.httpClient = Axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        // 'Accept': 'application/json;charset=UTF-8'
      },
      // transformRequest: data => {
      //   data.open_id = Storage.get('loginToken');
      //   data.tag = 'wx';
      //   return qs.stringify(data);
      // },
      // responseType: 'json',
    });
  }

  post<T = any>(url: string, data: any = {}, headers?: any) {
    return new Promise<T>((resolve, reject) => {
      data.open_id = Storage.get('loginToken');
      data.tag = 'wx';
      this.httpClient.post(url, qs.stringify(data), { headers}).then((response: AxiosResponse<IResponse<T>>) => {
        const result: any = this.parse(response);
        if (response.status === 200 && result.data.errcode === 0) {
          resolve(result);
        } else {
          this.unifyError(reject, url)(response);
        }
      })
    });
  }
  unifyError(reject: (error: any) => void, url?: string) {
    return (error: any) => {
      Toast.fail(error.data.msg, 2, undefined, false);
      if (error.data.errcode === 10001 && url !== '/wechat/isBind') {
        Navigate.route.replace('/login');
      }
      reject(error.data);
      console.log(error.data, url);
    };
  }

  parse(data: any) {
    return typeof data === 'string'? JSON.parse(data): data;
  }
}

interface IResponse<T> {
  errcode: number;
  msg: string;
  data: T;
}
