import React, { RefObject, createRef, PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import Tabs from '../../components/tabs/tabs';
import { TabItem, PageOption, OrderDetail } from '../../model';
import TimePicker, { ITimePickerState } from '../../components/time-picker/time-picker4';
import './commision-list.scss';
import { OrderService } from '../../service/order.service';
import { LogisticsService } from '../../service/logistics.service';
import { CommisionService } from '../../service/commision.service';
import moment from 'moment';
import medicineIcon from '../../assets/img/my-order/medicine.png';
import clientIcon from '../../assets/img/repository/storeIcon.png';
import { observer, inject } from 'mobx-react';
import { observable, action, computed } from 'mobx';
import NoResult from '../../components/no-result/no-result';
import { Storage } from '../../service/core/storage';
import { Navigate } from '../../tools/navigate';
import { Modal, List, Button, Toast } from 'antd-mobile';
import download from '../../assets/img/download.png';
import BottomRefresh from '../../components/bottom-refresh/bottom-refresh';
import phoneIcon from '../../assets/img/phone.png';
import Listener from '../../service/core/global.service';
import ClipboardJS from 'clipboard';
// import { bindLifecycle } from 'react-keep-alive';

@inject('store')
@observer
// @bindLifecycle
export default class CommisionList extends PureComponent<any, any> {

  stateIndex: number = 0;

  roleType: number = 0;

  getlistTimer: NodeJS.Timer;

  @observable isLoading: boolean = false;

  @observable noResult: boolean = false;

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  admin_id: number;

  query = {
    start_time: this.formatDate(moment(new Date()).subtract(3, 'days').hour(0).minute(0)),
    end_time: this.formatDate(moment(new Date()).hour(23).minute(59)),
    state: ''
  };
  newQuery = {
    start_time: this.formatDate(moment(new Date()).subtract(3, 'days').hour(0).minute(0)),
    end_time: this.formatDate(moment(new Date()).hour(23).minute(59)),
    business_name: '',
    state: ''
  }
  tabs: TabItem[] = [
    { title: '全部' },
    { title: '未发起' },
    { title: '已发起' },
    { title: '已通过' },
    { title: '驳回' }
  ];

  constructor(props: any) {
    super(props);
    const { store } = this.props;
    this.roleType = Number(Storage.get('roleType')) ||  0;
    this.admin_id = Number(Storage.get('adminID')) ||  0;
    this.state = {
      orderList: [],
      downloadVisible: false,
      currentUrl: '',
    };
    if (store.orderListTime.startTime) {
      this.newQuery.start_time = store.orderListTime.startTime;
      this.newQuery.end_time = store.orderListTime.endTime;
      this.newQuery.state = store.orderListTime.state;
    }
    if (this.newQuery.state) {
      this.stateIndex = +this.newQuery.state + 1;
    }
    this.getCustomerList();
    const clipBoard = new ClipboardJS('.commisionBtn');
    clipBoard.on('success', () => {
      Toast.info('复制成功！');
      this.setState({
        downloadVisible: false
      })
    })
    this.getlistTimer = setInterval(() => {
      this.stateSearch();
    }, 300000); // 设置5分钟请求一次列表
  }

  componentWillUnmount() {
    this.setOrderTime();
    clearInterval(this.getlistTimer);
  }
  customerSearch = () => {
    this.setState({dataList: []});
    this.getCustomerList();
  }
  componentDidActivate() {
    Listener.clearComponentCacheTrigger.next({name: 'orderList', disabled: false});
  }

  @action
  setOrderTime() {
    const { orderListTime } = this.props.store;
    orderListTime.startTime = this.newQuery.start_time;
    orderListTime.endTime = this.newQuery.end_time;
    orderListTime.state = this.newQuery.state;
  }

  @computed get createOrderData() {
    return this.props.store.createOrder;
  }

  tabChange = (index: number) => {
    this.stateIndex = index;
    if (index) {
      this.newQuery.state = `${index - 1}`;
    } else {
      this.newQuery.state = '';
    }
    this.stateSearch();
  }

  async getList() {
    this.noResult = false;
    this.isLoading = true;
    const data = await OrderService.orderList(this.pageOption.page, this.pageOption.rows,this.query);
    this.pageOption.page = data.current_page;
    this.pageOption.rows = +data.per_page;
    this.pageOption.total = +data.total;
    data.data && data.data.forEach((item: any) => {
      Object.assign(item, item.order_goods[0]);
      item.stateName = this.tabs[item.tc_state + 1].title;
    });
    const lastResult = [...this.state.orderList, ...data.data];
    if (!lastResult.length) {
      this.noResult = true;
    }
    this.setState({
      orderList: lastResult
    });
    this.isLoading = false;
  }
  async getCustomerList() {
    this.noResult = false;
    this.isLoading = true;
    const data = await CommisionService.getTCList(this.pageOption.page, this.pageOption.rows,this.newQuery);
    this.pageOption.page = data.current_page;
    this.pageOption.rows = +data.per_page;
    this.pageOption.total = +data.total;
    data.data && data.data.forEach((item: any) => {
      Object.assign(item, item.order_goods[0]);
      item.stateName = this.tabs[item.tc_state + 1].title;
    });
    const lastResult = [...this.state.orderList, ...data.data];
    if (!lastResult.length) {
      this.noResult = true;
    }
    this.setState({
      orderList: lastResult
    });
    this.isLoading = false;
  }

  stateSearch() {
    this.setState({
      orderList: []
    });
    this.pageOption.page = 1;
    this.getCustomerList();
  }

  pageSearch() {
    if (this.isLoading) { return ;}
    this.pageOption.page++;
    this.getCustomerList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.orderList.length >= this.pageOption.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  timeSearch = (v: ITimePickerState) => {
    this.newQuery.start_time = this.formatDate(v.startTime);
    this.newQuery.end_time = this.formatDate(v.endTime);
    this.newQuery.business_name = v.business_name
    this.stateSearch();
  }

  formatDate(date: Date | any) {
    if (date instanceof Date) {
      return moment(date.getTime()).format('YYYY-MM-DD HH:mm:ss');
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  toDetail(item: any) {
    Navigate.route.push(`/orderDetail/${item.Id}`);
  }

  copyUrl = () => {

  }

  downLoad = async () => {
    Toast.loading('加载中...', 0);
    // const data = await LogisticsService.download(this.query.start_time, this.query.end_time);
    this.setState({
      downloadVisible: true,
      currentUrl: 'https://www.goodyiyao.com/api/order/getTCList?start_time=' + this.newQuery.start_time + '&end_time=' + this.newQuery.end_time + '&tag=wx&open_id=' + Storage.get('loginToken')
    });
    Toast.hide();
  }

  @action
  async resubmit(order: OrderDetail) {
    const data = await OrderService.detail(order.Id.toString());
    this.createOrderData.id = data.Id;
    this.createOrderData.medicineList = [];
    this.props.store.checkedMedicines = data.order_goods;
    this.createOrderData.cilent = {name: data.business_name};
    this.createOrderData.remark = data.description;
    this.createOrderData.imgList = data.images instanceof Array? data.images.map((img: string, index:number) => ({url: img, id: index})): [];
    Navigate.route.push('/createOrder');
  }

  async rejectOrder(order: OrderDetail) {
    const alertInstance = Modal.prompt('驳回原因', '输入驳回原因',
      [
        { text: '取消', onPress: () => alertInstance.close() },
        {
          text: '提交',
          onPress: async value => {
            Toast.loading('正在驳回...');
            await CommisionService.commisionStateSwitch(order.Id, 3, value);
            Toast.hide();
            this.stateSearch();
          },
        },
      ], 'default', undefined, ['请输入驳回原因'])

  }

  async revokeOrder(order: OrderDetail) {
    const alertInstance = Modal.alert('提交订单', '确定要提交吗？', [
      { text: '取消', onPress: () => alertInstance.close() },
      { text: '确定', onPress: async () => {
        Toast.loading('正在提交...');
        await CommisionService.commisionStateSwitch(order.Id, 1);
        Toast.hide();
        this.stateSearch();
       }
      },
    ]);
  }

  async makeOutOrder(order: any) {
    const alertInstance = Modal.alert('订单通过', '确定要通过吗？', [
      { text: '取消', onPress: () => alertInstance.close() },
      { text: '确定', onPress: async () => {
        Toast.loading('正在通过...');
        await CommisionService.commisionStateSwitch(order.Id, 2);
        Toast.hide();
        this.stateSearch();
       }
      },
    ]);
  }

  judgeBtnByRole(orderItem: OrderDetail) {
    if (this.roleType === 1 && (orderItem.tc_state === 0 || orderItem.tc_state === 3)) {
      return <button className="orderBtn" onClick={this.revokeOrder.bind(this, orderItem)}>提交</button>;
    }
    if (this.roleType === 5 && orderItem.tc_state === 1) {
      return [
        <button className="orderBtn" onClick={this.rejectOrder.bind(this, orderItem)}>驳回</button>,
        <button className="orderBtn" onClick={this.makeOutOrder.bind(this, orderItem)}>通过</button>
      ];
    }
  }

  orderItems() {
    return this.state.orderList.map((item: any, index: number) => {
      return <div className="orderItem" key={index}>
      <div className="time-state">
        <div className="time">下单时间：</div>
        <div className="state">{item.create_time}</div>
        <div className="stateType">
          <label >{item.stateName}</label>
        </div>
      </div>
      <div className="storeName">
        <img alt='' src={clientIcon} className="storeIcon"></img>
        <label>{item.business_name}</label>
      </div>
      {/* <div className="storeName">
        <label>业务员：</label>
        <label>{item.sales_man}</label>
        <a href={'tel:'+ item.sales_man_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
      </div> */}
      <div className="storeName">
        <div className="storeName">
          <label className="storeTitle">业务员：</label>
          <label className="storeValueCol">{item.sales_man}</label>
          <a href={'tel:'+ item.sales_man_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
        </div>
        { (item.state === 1 || item.state === 2)? <div className="storeName">
          <label className="storeTitle">开票员：</label>
          <label className="storeValueCol">{item.invoice_clerk}</label>
          <a href={'tel:'+ item.invoice_clerk_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
        </div>: ''}
      </div>
      {
        item.state === 2? <div className="storeName">
          <label>发货类型：</label>
          <label className="red">{(item.shipping_name === '客户自提' || item.shipping_name === '自主配送')? item.shipping_name: '物流跟踪'}</label>
        </div>: ''
      }

      <div className="icon-detail">
        <img className="medicineIcon" src={medicineIcon} alt=""></img>
        <div className="detail">
          <div className="detail-field flex">
            <label>{item.goods_name}</label>
            <label>{item.goods_spec}</label>
          </div>
          <div className="detail-field flex">
            <label>￥{item.price}</label>
            <label>*{item.amount}{item.unit}</label>
          </div>
          <div>{item.company}</div>
        </div>
      </div>
      <div className="price-query">
        <div className='amount red'>
          总价：￥{item.total}<br/>
          总提成：￥{item.order_tc_price}
        </div>
        <div className="btns">
          { this.judgeBtnByRole(item) }
          {/*<button className="orderBtn" onClick={this.toDetail.bind(this, item)}>详情</button>*/}
        </div>
      </div>
    </div>;
    });
  }

  render() {
    return (
      <div className="orderList" onScroll={this.scrollLoad}>
        <div className="header">
          <NavBar title="提成列表"></NavBar>
          <Tabs tabs={this.tabs} index={this.stateIndex} onChange={this.tabChange}></Tabs>
          <TimePicker onSearch={this.timeSearch} startTime={this.newQuery.start_time} endTime={this.newQuery.end_time}></TimePicker>
        </div>
        <div className="list">
          {  this.orderItems() }
          {this.noResult && <NoResult></NoResult>}
        </div>
        {
          !this.noResult && <BottomRefresh
          total={this.pageOption.total}
          infactTotal={this.state.orderList.length}
          isLoading={this.isLoading}
          onSearch={this.pageSearch}></BottomRefresh>
        }
        <img className="downLoad" alt="download" src={download} onClick={this.downLoad}/>
        <Modal
            popup = {true}
            visible={this.state.downloadVisible}
            onClose={() => this.setState({downloadVisible: false})}
            animationType="slide-up"
        >
          <List renderHeader={() => <div className="flowInquiryInputUrlTitle">复制下载地址</div>} className="popup-list">
            <div className="flowInquiryInputUrl">
              <div className="tip">(请复制连接到手机自带浏览器进行下载)</div>
              <input id="flowInquiryInputUrl" readOnly defaultValue={this.state.currentUrl} />
            </div>
            <List.Item>
              <Button type="primary" className="commisionBtn" onClick={this.copyUrl} data-clipboard-action="copy" data-clipboard-target="#flowInquiryInputUrl">复制地址</Button>
            </List.Item>
          </List>
        </Modal>
      </div>
    );
  }
}
