import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './goods-list.scss';
import { observer, inject } from 'mobx-react';
import { RepositoryQuery, ReposieoryItem } from '../../model';
import storeImg from '../../assets/img/repository/storeIcon.png';
import SearchBar from '../../components/search-bar/search-bar';
import checkedIcon from '../../assets/img/checked.png';
import uncheckedIcon from '../../assets/img/unchecked.png';
import { action } from 'mobx';
import { Navigate } from '../../tools/navigate';
import { GoodsService } from '../../service/goods.service';
import BottomRefresh from '../../components/bottom-refresh/bottom-refresh';
import { Toast } from 'antd-mobile';

@inject('store')
@observer
export default class GoodsList extends PureComponent<any, any> {

  query: RepositoryQuery;

  medicineList: ReposieoryItem[] = [];

  selectedMedicine: Map<string, ReposieoryItem> = new Map();

  inputInterval: NodeJS.Timeout = setTimeout(() => {}, 0);

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      selectedList: []
    };
    this.query = {
      goods_name: '',
      page: 1,
      rows: 20,
      total: 0
    };
  }

  componentDidMount() {
    this.getList();
  }

  setQueryName = (v: string) => {
    this.query.goods_name = v;
  }

  selectMedicine(index: number) {
    this.medicineList.map((val)=>{
      val.checked = false
    })
    this.selectedMedicine.clear() 
    this.medicineList[index].checked = !this.medicineList[index].checked;
    const item = this.medicineList[index];
    if (this.medicineList[index].checked) {
      this.selectedMedicine.set(item.goods_code, item);
    } else {
       this.selectedMedicine.delete(item.goods_code);
    }
    this.setState({
      dataList: JSON.parse(JSON.stringify(this.medicineList))
    });
  }

  getList = async () => {
    this.setState({loading: true});
    const data = await GoodsService.getGoodsList(this.query);
    this.setState({loading: false});
    data.data.forEach((item: any) => {
      Object.assign(item, item.inventory? item.inventory: {});
    });
    this.query.page = data.current_page;
    this.query.rows = +data.per_page;
    this.query.total = data.total;
    data.data.forEach((item: any) => {
      if (this.selectedMedicine.has(item.goods_code)) {
        item.checked = true;
      }
    });
    this.setState({
      dataList: [...this.state.dataList, ...data.data]
    });
    this.medicineList = this.state.dataList;
  }

  pageSearch = () => {
    this.query.page++;
    this.getList()
  }

  nameSearch = () => {
    this.setState({dataList: []});
    this.medicineList = [];
    this.query.page = 1;
    this.getList();
  }

  inputSearch = (v: string) =>{
    this.query.goods_name = v;
    clearTimeout(this.inputInterval);
    this.inputInterval = setTimeout(() => {
      this.nameSearch();
    }, 300);
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.query.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  @action
  submitData = () => {
    const checkedList = Array.from(this.selectedMedicine).map(item => item[1]);
    checkedList.forEach(item => item.checked = false);
    const foundZeroGood = checkedList.find(item => !+item.count)
    if (foundZeroGood) {
      Toast.offline(`${foundZeroGood.goods_name} ${foundZeroGood.goods_spec} 库存不足`, 1.5, undefined, false);
    } else {
      this.props.store.checkedMedicines = checkedList;
      Navigate.route.goBack();
    }
  }

  repositoryList() {
    return this.state.dataList.map((item: ReposieoryItem, index: number) => {
      return <div className="item" key={index} onClick={this.selectMedicine.bind(this, index)}>
        <div className="medName">
          <div className="IconName">
            <img src={storeImg} alt=""></img>
            <div className={+item.count === 0? 'red name': 'name'}>{item.goods_name + (item.name? `(${item.name})`: '')}</div>
          </div>
          <img src={item.checked? checkedIcon: uncheckedIcon} className="checkIcon" alt=""></img>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">商品编码</div>
            <div className="paramValue">{item.goods_code}</div>
          </div>
          <div className="param">
            <div className="paramName">药品规格</div>
            <div className="paramValue">{item.goods_spec}</div>
          </div>
          <div className="param">
            <div className="paramName">整件包装</div>
            <div className="paramValue">{item.pack}</div>
          </div>
          <div className="param">
            <div className="paramName">业务库存</div>
            <div className="paramValue">{item.count}</div>
          </div>
          <div className="param">
            <div className="paramName">单位</div>
            <div className="paramValue">{item.unit}</div>
          </div>
          <div className="param">
            <div className="paramName">生产厂家</div>
            <div className="paramValue">{item.made_in}</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="goodsList" onScroll={this.scrollLoad}>
        <div className="repostHeader">
          <NavBar title="商品选择"></NavBar>
          <SearchBar placeholder="请输入首拼或商品名称" onChange={this.setQueryName} onInput={this.inputSearch} onSearch={this.nameSearch}></SearchBar>
        </div>
        <div className="reposReultList">
          {this.repositoryList()}
        </div>
        <BottomRefresh
          total={this.query.total}
          infactTotal={this.state.dataList.length}
          isLoading={this.state.loading}
          onSearch={this.pageSearch}></BottomRefresh>
        <button className="goodsListBtton" onClick={this.submitData}>添加商品</button>
      </div>
    )
  }

}

