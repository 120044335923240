import React, { PureComponent, ChangeEvent } from 'react'
import "./search-bar.scss";
import { SearchBarProps } from '../../model';
import searchIcon from '../../assets/img/search.png';

export default class SearchBar extends PureComponent<SearchBarProps, any> {

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  }

  onInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.onInput) {
      this.props.onInput(event.target.value);
    }
  }

  render() {
    return (
      <div className="searchBar">
        <div className="searchBarInput">
          <img src={searchIcon} alt=""></img>
          <input placeholder={this.props.placeholder} onChange={this.onChange} onInput={this.onInput} />
        </div>
        <button className="searchBar-btn" onClick={this.props.onSearch}>{this.props.buttonName || '搜索'}</button>
      </div>
    )
  }
}
