import { Http } from "./http";
import { AppDataBase } from "./database";

export abstract class ServiceBasic {

  protected static http: Http = new Http();

  protected static database: AppDataBase= new AppDataBase();

}
