import React, { PureComponent } from 'react'
import { DatePicker } from 'antd-mobile';
import moment, { Moment } from 'moment';
import arrive from '../../assets/img/arrive.png';
import {InputItem} from 'antd-mobile';
import './time-picker.scss';
export interface ITimePickerState {
  timePickerVisible: boolean;
  startTimeText: string;
  endTimeText: string;
  startTime: Date;
  endTime: Date;
  business_name: any
}

export default class TimePicker extends PureComponent<any, ITimePickerState> {

  openType: 'startTime' | 'endTime' = 'startTime';

  constructor(props: any) {
    super(props);
    this.state = {
      timePickerVisible: false,
      startTimeText: '',
      endTimeText: '',
      startTime: new Date(),
      endTime: new Date(),
      business_name: ''
    };
  }

  componentDidMount() {
    if (this.props.startTime) {
      this.setTime().startTime(this.props.startTime);
    }
    if (this.props.endTime) {
      this.setTime().endTime(this.props.endTime);
    }
  }

  setTime() {
    return {
      endTime: (v: Date | Moment) => {
        this.setState({
          endTime: moment(v).toDate(),
          endTimeText: moment(v).format('YYYY/MM/DD')
        });
      },
      startTime: (v: Date | Moment) => {
        this.setState({
          startTime: moment(v).toDate(),
          startTimeText: moment(v).format('YYYY/MM/DD')
        });
      }
    };
  }
  
  
  showDatePicker(type: 'startTime' | 'endTime') {
    this.openType = type;
    this.setState({
      timePickerVisible: true
    });
  }

  close = () => {
    this.setState({
      timePickerVisible: false
    });
  }

  timeValueChange(v: Date) {
    if (this.openType === 'startTime') {
      v.setHours(0);
      v.setMinutes(0);
      this.setState({
        startTimeText: moment(v).format('YYYY/MM/DD'),
        startTime: v
      });
    } else {
      v.setHours(23);
      v.setMinutes(59);
      this.setState({
        endTimeText: moment(v).format('YYYY/MM/DD'),
        endTime: v
      });
    }
  }

  callback = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.state);
    }
  }

  render() {
    // const {business_name} = this.state
    return (
      <div className="timePicker timePicker4">
        <div className="content">
          <div style={{display:'flex',width:'100%',flexDirection:'row'}}>
            <div className="timeShow" onClick={this.showDatePicker.bind(this, 'startTime')} style={{position:'relative'}}>
              <label className="timeValue" style={{textIndent:'0.5rem'}}>{this.state.startTimeText || "选择时间"}</label>
              <img src={arrive} alt="" className="downArrive" style={{right:'0.3rem'}}></img>
            </div>
            <div style={{width:'1rem',lineHeight:'0.7rem'}}>~</div>
            <div className="timeShow" onClick={this.showDatePicker.bind(this, 'endTime')}>
              <label className="timeValue" style={{textIndent:'0.5rem'}}>{this.state.endTimeText || "选择时间"}</label>
              <img src={arrive} alt="" className="downArrive"></img>
            </div>
          </div>
          <InputItem defaultValue = "" onChange ={(business_name) => {this.setState({business_name})}} style={{height:'0.6rem',fontSize:'0.3733rem',textIndent:'0.28rem'}} placeholder="输入客户名称(可选)" />
          <div className="flex flexCenter">
            <button className="inquiryButton" onClick={this.callback}>查询</button>
          </div>
        </div>
        <DatePicker
          visible={this.state.timePickerVisible}
          onOk={this.close}
          title={this.openType === 'startTime'? '开始时间': '结束时间'}
          mode="date"
          onDismiss={() => this.close()}
          onChange={(v: Date) => this.timeValueChange(v)}></DatePicker>
      </div>
    )
  }
}
