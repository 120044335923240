import React, { RefObject, createRef, PureComponent } from 'react'
import medicineIcon from '../../../assets/img/my-order/medicine.png';
import clientIcon from '../../../assets/img/cilent-store.png'
import { OrderService } from '../../../service/order.service';
import './order-detail.scss';
import NavBar from '../../../components/navBar/nav-bar';
import { ReposieoryItem, TabItem } from '../../../model';
import { Navigate } from '../../../tools/navigate';
import { Modal, Toast } from 'antd-mobile';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { observable, action, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Storage } from '../../../service/core/storage';
import LogisticsPopup from '../../../common/logistics-popup/logistics-popup';
import phoneIcon from '../../../assets/img/phone.png';

@inject('store')
@observer
export default class OrderDetail extends PureComponent<any, any> {

  orderID: string;

  @observable imgViewerVisible: boolean  = false;

  @observable viewerIndex: number = 0;

  roleType: number;

  admin_id: number;

  expressInfoPopup: RefObject<LogisticsPopup> = createRef<LogisticsPopup>();

  @observable medicineListData: ReposieoryItem[] = [];

  tabs: TabItem[] = [
    { title: '全部' },
    { title: '未开票' },
    { title: '已开票' },
    { title: '已完成' },
    { title: '已撤销' },
    { title: '驳回' }
  ];

  @computed get createOrderData() {
    return this.props.store.createOrder;
  }

  constructor(props: any) {
    super(props);
    this.orderID = this.props.match.params.orderID;
    this.roleType = Number(Storage.get('roleType')) ||  0;
    this.admin_id = Number(Storage.get('adminID')) ||  0;
    this.state = {
      orderDetail: {}
    };
    console.log(navigator.userAgent);
  }

  componentDidMount() {
    this.getDetail();
  }

  toLogistics(orderID: string) {
    Navigate.route.push(`/logistic/${orderID}`)
  }

  resetSearch() {
  }

  checkGoods(index: number) {
    try {
      const medicine = JSON.parse(JSON.stringify(this.medicineListData[index]));
      medicine.checked = !medicine.checked;
      this.medicineListData[index] = medicine;
    } catch (error) {
      return ;
    }
  }

  async rejectOrder() {
    const alertInstance = Modal.prompt('驳回原因', '输入驳回原因',
      [
        { text: '取消', onPress: () => alertInstance.close() },
        {
          text: '提交',
          onPress: async value => {
            Toast.loading('正在驳回...');
            await OrderService.reject(+this.orderID, value);
            Toast.hide();
            this.getDetail();
          },
        },
      ], 'default', undefined, ['请输入驳回原因'])
  }

  async revokeOrder() {
    const alertInstance = Modal.alert('订单撤销', '确定要撤销吗？', [
      { text: '取消', onPress: () => alertInstance.close() },
      { text: '确定', onPress: async () => {
        Toast.loading('正在撤销...');
        await OrderService.revoke(+this.orderID);
        Toast.hide();
        this.getDetail();
       }
      },
    ]);
  }

  async makeOutOrder() {
    const alertInstance = Modal.alert('订单开票', '确定要开票吗？', [
      { text: '取消', onPress: () => alertInstance.close() },
      { text: '确定', onPress: async () => {
        Toast.loading('正在开票...');
        await OrderService.makeOutOrder(this.orderID);
        Toast.hide();
        this.getDetail();
       }
      },
    ]);
  }

  @action
  async resubmit(order: any) {
    const data = await OrderService.detail(order.Id.toString());
    this.createOrderData.id = data.Id;
    this.createOrderData.medicineList = [];
    this.props.store.checkedMedicines = data.order_goods;
    this.createOrderData.cilent = {name: data.business_name};
    this.createOrderData.remark = data.description;
    this.createOrderData.imgList = data.images instanceof Array? data.images.map((img: string, index:number) => ({url: img, id: index})): [];
    Navigate.route.push('/createOrder');
  }

  async getDetail() {
    Toast.loading('加载中...')
    const detail = await OrderService.detail(this.orderID);
    detail.stateName = this.tabs[detail.state + 1].title
    detail.viewImgs = detail.images? detail.images.map((item: any) => ({src: item, alt: ''})): [];
    this.medicineListData = detail.order_goods;
    this.setState({
      orderDetail: detail
    });
    Toast.hide();
  }

  comsignmentOrder(order: any) {
    if (this.expressInfoPopup.current) {
      this.expressInfoPopup.current.open(order.Id);
    }
  }

  openCloseViewer = () => {
    this.imgViewerVisible = !this.imgViewerVisible;
  }

  judgeBtnByRole(orderItem: any) {
    if (this.roleType === 1 || (this.roleType === 4 && this.admin_id === orderItem.admin_id)) {
      if (orderItem.state === 0) {
        return <button className="orderBtn" onClick={this.revokeOrder.bind(this, orderItem)}>撤销</button>;
      } else if(orderItem.state === 4) {
        return <button className="orderBtn" onClick={this.resubmit.bind(this, orderItem)}>重新提交</button>;
      }
    } else if(this.roleType === 2) {
      if (orderItem.state === 0) {
        return [
          <button className="orderBtn" onClick={this.makeOutOrder.bind(this, orderItem)}>开票</button>,
          <button className="orderBtn" onClick={this.rejectOrder.bind(this, orderItem)}>驳回</button>
        ];
      } else if (orderItem.state === 1) {
        return <button className="orderBtn" onClick={this.comsignmentOrder.bind(this, orderItem)}>发货</button>;
      }
    } else if(this.roleType === 3) {
      if (orderItem.state === 1) {
        return <button className="orderBtn" onClick={this.comsignmentOrder.bind(this, orderItem)}>发货</button>;
      } else if (orderItem.state === 2) {
        return <button className="orderBtn" onClick={this.comsignmentOrder.bind(this, orderItem)}>修改物流</button>;
      }
    }
  }

  medicineList() {
    return this.medicineListData &&  this.medicineListData.map((item: ReposieoryItem, index: number) => {
      return <div className={item.checked? 'icon-detail icon-detail-gray': 'icon-detail'} key={index} onClick={this.checkGoods.bind(this, index)}>
        <img className="medicineIcon" src={medicineIcon} alt=""></img>
        <div className="detail">
          <div>{item.goods_code}</div>
          <div>{item.goods_name}</div>
          <div>￥{item.price}</div>
          <div className="">{item.company}</div>
        </div>
        <div className="rightData flex">
          <div className="simplyPrice flex">
            <label>
            {item.goods_spec}
            </label>
            <label>
            *{item.amount}{item.unit}
            </label>
          </div>
          <div className="red simplyComputed">
            <div>提成:￥{parseFloat(item.tc_price)}</div>
            <div>小计：{(+item.price * +item.amount).toFixed(2)}元</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    const { orderDetail } = this.state;
    return (
      <div className="orderDetail">
        <div className="header">
          <NavBar title="订单详情"></NavBar>
        </div>
        <div className="orderItem">
          <div className="time-state">
            <div className="time">下单时间：</div>
            <div className="state">{orderDetail.create_time}</div>
            <div className="stateType">
              {orderDetail.stateName}
            </div>
          </div>
          <div className="storeName">
            <img alt='' src={clientIcon} className="storeIcon"></img>
            <label>{orderDetail.business_name}</label>
          </div>
          <div className="storeName">
            <div className="storeName zeroMargin">
              <label className="storeTitle">业务员：</label>
              <label className="storeValueCol">{orderDetail.sales_man}</label>
              <a href={'tel:'+ orderDetail.sales_man_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
            </div>
            { (orderDetail.state === 1 || orderDetail.state === 2)?<div className="storeName zeroMargin">
              <label className="storeTitle">开票员：</label>
              <label className="storeValueCol">{orderDetail.invoice_clerk}</label>
              <a href={'tel:'+ orderDetail.invoice_clerk_num}><img className="phoneIcon" src={phoneIcon} alt=""/></a>
            </div>: ''}
          </div>
          {
            orderDetail.state === 2? <div className="storeName">
              <label>物流类型：</label>
              <label className="red">{(orderDetail.shipping_name === '客户自提' || orderDetail.shipping_name === '自主配送')? orderDetail.shipping_name: '物流跟踪'}</label>
            </div>: ''
          }

          {
            orderDetail.reason? <div className="storeName">
            <label>驳回原因：</label>
            <label className="red">{orderDetail.reason}</label>
          </div>: ''
          }
          { this.medicineList()}
          <div className="storeName">
            <label>订单备注：</label>
            <label>{orderDetail.description}</label>
          </div>
          <div className="storeName storeNameBlock">
            <label>订单图片：</label>
            {orderDetail.images && orderDetail.images.map((item: string, i: number) => <img alt="" key={i} className="remarkImg" src={item} onClick={this.openCloseViewer}></img>)}
          </div>
          <div className="price-query">
            <div className='amount red'>
              <div>总价：{orderDetail.total}元</div>
              <div>成本价：{orderDetail.cb_price}元</div>
            </div>
            <div className="btns red">
              <div>总提成：{orderDetail.order_tc_price}元</div>
              <div>邮费：{orderDetail.shipping_price}元</div>
            </div>
          </div>
          <div className="price-query">
            <div className="btns">
              { this.judgeBtnByRole(orderDetail) }
              { (orderDetail.state === 2 && !(orderDetail.shipping_name === '客户自提' || orderDetail.shipping_name === '自主配送'))? <button className="orderBtn" onClick={this.toLogistics.bind(this, orderDetail.Id)}>查看物流</button>: ''}
            </div>
          </div>
        </div>
        <LogisticsPopup ref={this.expressInfoPopup} onSubmit={this.getDetail.bind(this)}></LogisticsPopup>
        {/* <Viewer visible={this.imgViewerVisible} images={this.state.orderDetail.viewImgs} onClose={this.openCloseViewer} drag={true} zoomSpeed={0.1}></Viewer> */}
        {this.imgViewerVisible && (
          <Lightbox
            mainSrc={orderDetail.images[this.viewerIndex]}
            nextSrc={orderDetail.images[(this.viewerIndex + 1) % orderDetail.images.length]}
            prevSrc={orderDetail.images[(this.viewerIndex + orderDetail.images.length - 1) % orderDetail.images.length]}
            onCloseRequest={() => this.imgViewerVisible = false}
            onMovePrevRequest={() => this.viewerIndex = (this.viewerIndex + orderDetail.images.length - 1) % orderDetail.images.length }
            onMoveNextRequest={() => this.viewerIndex = (this.viewerIndex + 1) % orderDetail.images.length }
          />
        )}
      </div>
    );
  }
}
