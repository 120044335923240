import React, { PureComponent } from 'react'
import NavBar from '../../components/navBar/nav-bar';
import './flow-inquiry.scss';
import TimePicker from '../../components/time-picker/time-picker5';
import storeImg from '../../assets/img/repository/storeIcon.png';
import moment from 'moment';
import { LogisticsService } from '../../service/logistics.service';
import { PageOption } from '../../model';
import NoResult from '../../components/no-result/no-result';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import download from '../../assets/img/download.png';
import { Navigate } from '../../tools/navigate';
import { Modal, List, Button, Toast } from 'antd-mobile';
import { Storage } from '../../service/core/storage';
import ClipboardJS from 'clipboard';

@observer
export default class FlowInquiry extends PureComponent<any, any> {

  pageOption: PageOption = {
    page: 1,
    rows: 20,
    total: 0
  };

  @observable noResult: boolean = false;

  query: {start_time: string, end_time: string, goods_name: string};

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      downloadVisible: false,
      currentUrl: '',
      loading: false,
      sales: 0,
      sum: 0,
      total_fee: 0
    };
    this.query = {
      goods_name: Storage.getParse('flowInquery') ? Storage.getParse('flowInquery').goods_name : '',
      start_time: Storage.getParse('flowInquery') ? Storage.getParse('flowInquery').start_time : this.formatDate(moment(new Date()).hour(0).minute(0)),
      end_time: Storage.getParse('flowInquery') ? Storage.getParse('flowInquery').end_time : this.formatDate(moment(new Date()).hour(23).minute(59)),
    };
    this.getList();
    const clipBoard = new ClipboardJS('.flowInquiryBtn');
    clipBoard.on('success', () => {
      Toast.info('复制成功！');
      this.setState({
        downloadVisible: false
      })
    })
  }

  async getList() {
    if (!this.state.loading) {
      this.noResult = false;
      const data = await LogisticsService.getListV2(this.pageOption, this.query);
      this.pageOption.page = data.list.current_page;
      this.pageOption.rows = +data.list.per_page;
      this.pageOption.total = +data.list.total;
      this.setState({
        dataList: [...this.state.dataList, ...data.list.data],
        sales: data.sales,
        sum: data.sum,
        total_fee: data.total_fee,
      });
      this.setState({
        loading: false
      });
      if (!this.state.dataList.length) {
        this.noResult = true;
      }
    }
  }

  timeSearch = (v: any) => {
    this.query.start_time = this.formatDate(v.startTime);
    this.query.end_time = this.formatDate(v.endTime);
    this.query.goods_name = v.goodsName;
    Storage.set('flowInquery', this.query)
    this.resetSearch();
  }

  resetSearch() {
    this.pageOption.page = 1;
    this.setState({
      dataList: []
    }, () => {
      this.getList();
    });
  }

  pageSearch() {
    this.pageOption.page++;
    this.setState({
      loading: true
    });
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.pageOption.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  formatDate(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  downLoad = async () => {
    Toast.loading('加载中...', 0);
    const data = await LogisticsService.download(this.query.start_time, this.query.end_time);
    this.setState({
      downloadVisible: true,
      currentUrl: data.url
    });
    Toast.hide();
  }

  toDetail(item: any, query: any) {
    Navigate.route.push(`/flowDetail/${item.goods_num}/${query.start_time}/${query.end_time}`);
  }

  copyUrl = () => {

  }

  inquiryList() {
    return this.state.dataList.map((item: any, index: number) => {
      return <div className="item" key={index} onClick={this.toDetail.bind(this, item, this.query)}>
        <div className="medName">
          <img src={storeImg} alt=""></img>
          <div className="name">{item.goods_name + (item.name? `(${item.name})`: '')}</div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">剂型</div>
            <div className="paramValue">{item.dosage_form}</div>
          </div>
          <div className="param">
            <div className="paramName">规格</div>
            <div className="paramValue">{item.goods_spec}</div>
          </div>
          <div className="param">
            <div className="paramName">单位</div>
            <div className="paramValue">{item.unit}</div>
          </div>
          <div className="param">
            <div className="paramName">厂商</div>
            <div className="paramValue">{item.production}</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="flowInquiry" onScroll={this.scrollLoad} style={{paddingTop: "0"}}>
        <div className="header">
          <NavBar title="流向查询"></NavBar>
          <TimePicker onSearch={this.timeSearch}  startTime={this.query.start_time} endTime={this.query.end_time} goodsName={this.query.goods_name}></TimePicker>
        </div>
        <div className="flowListPool">
          <div>
            <span>订单数</span>
            <span>{this.state.sum}</span>
          </div>
          <div>
            <span>销量</span>
            <span>{this.state.sales}</span>
          </div>
          <div>
            <span>销售总价</span>
            <span>￥{this.state.total_fee}</span>
          </div>
        </div>
        <div className="flowInquiryList">
          { this.inquiryList() }
          { this.noResult && <NoResult></NoResult>}
        </div>
        <img className="downLoad" alt="download" src={download} onClick={this.downLoad}/>
        <Modal
          popup = {true}
          visible={this.state.downloadVisible}
          onClose={() => this.setState({downloadVisible: false})}
          animationType="slide-up"
        >
          <List renderHeader={() => <div className="flowInquiryInputUrlTitle">复制下载地址</div>} className="popup-list">
            <div className="flowInquiryInputUrl">
              <div className="tip">(请复制连接到手机自带浏览器进行下载)</div>
              <input id="flowInquiryInputUrl" readOnly defaultValue={this.state.currentUrl} />
            </div>
            <List.Item>
              <Button type="primary" className="flowInquiryBtn" onClick={this.copyUrl} data-clipboard-action="copy" data-clipboard-target="#flowInquiryInputUrl">复制地址</Button>
            </List.Item>
          </List>
        </Modal>
      </div>
    )
  }
}
