import { History, createHashHistory, createBrowserHistory } from "history";
import { AppConfig } from "../App.config";

export class Navigate {

  private static hashHistory: History<any> = createHashHistory();

  private static history: History<any> = createBrowserHistory();

  static get route(): History<any> {
    if (AppConfig.routeMode === 'hash') {
      return this.hashHistory;
    } else {
      return this.history;
    }
  }
}