import { Http } from "./core/http";
import { PageOption } from "../model";

export class NoticeService {

  protected static http: Http = new Http();

  static getList(pageOption: PageOption, query: any) {
    return this.http.post('/cglx/cgtz', { ...pageOption, ...query});
  }

  static detail(id: string) {
    return this.http.post('/cglx/cgtz', { id });
  }

  static readMark(id: string) {
    return this.http.post('/cglx/cgtzReadMark', { id });
  }

  static list() {
    return this.http.post('/Shipping/getList');
  }

  static consignment(shipping_name: string, shipping_num: string, order_id: string) {
    return this.http.post('/order/send', {shipping_name, shipping_num, order_id});
  }
}
