
import React, { PureComponent } from 'react'
import NavBar from '../../../components/navBar/nav-bar';
import { SalesmanQuery, ReposieoryItem } from '../../../model';
import { SalesmanService } from '../../../service/salesman.service';
import { Switch } from 'antd-mobile';
import "./salesman-list.scss";
import SearchBar from '../../../components/search-bar/search-bar';
import BottomRefresh from '../../../components/bottom-refresh/bottom-refresh';
import { Navigate } from '../../../tools/navigate';

export default class SalesmanList extends PureComponent<any, any> {

  query: SalesmanQuery;

  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      loading: false
    };
    this.query = {
      keyword: '',
      page: 1,
      rows: 20,
      total: 0,
    };
  }
  componentDidMount() {
    this.getList();
  }

  setQueryName = (v: string) => {
    this.query.keyword = v;
  }

  getList = async () => {
    this.setState({loading: true});
    const data = await SalesmanService.clerkList(this.query);
    this.query.page = data.current_page;
    this.query.rows = +data.per_page;
    this.query.total = data.total;
    this.setState({loading: false});
    this.setState({
      dataList: [...this.state.dataList, ...data.data]
    });
  }

  pageSearch = () => {
    this.query.page++;
    this.getList();
  }

  scrollLoad = ({target}: any) => {
    if (this.state.dataList.length >= this.query.total) { return ; }
    if ((target.scrollHeight - target.clientHeight - target.scrollTop) < 10 ) {
      this.pageSearch();
    }
  }

  nameSearch = () => {
    this.query.page = 1;
    this.setState({dataList: []});
    this.getList();
  }

  changeStatus = async (item: any, index: number) => {
    await SalesmanService.isCreate(item.admin_id, item.is_create === 1 ? 0 : 1);
    const list = [...this.state.dataList];

    list.forEach((e) => {
      if (item.admin_id === e.admin_id) {
        e.is_create = e.is_create === 1 ? 0 : 1;
      }
    });

    this.setState({
      dataList: list
    });
    // this.setState({
    //   dataList: list
    // });
  }

  repositoryList() {
    return this.state.dataList.map((item: any, index: number) => {
      return <div className="item" key={index}>
        <div className="medName">
          <div>
            <span>{item.name}</span>
          </div>
          <div>
            <span>{item.mobile_num}</span>
          </div>
        </div>
        <div className="line"></div>
        <div className="detailParams">
          <div className="param">
            <div className="paramName">本日销售额</div>
            <div className="paramValue paramPrice">￥{item.today_price}</div>
          </div>
          <div className="param">
            <div className="paramName">本月销售额</div>
            <div className="paramValue paramPrice">￥{item.month_price}</div>
          </div>
          <div className="param">
            <div className="paramName">是否允许下单</div>
            <div className="paramValue">{
              <Switch
                checked={item.is_create === 1 ? true : false}
                onChange={() => this.changeStatus(item, index)}
              />
            }</div>
          </div>
        </div>
      </div>;
    });
  }

  render() {
    return (
      <div className="salesmanList" onScroll={this.scrollLoad}>
        <div className="repostHeader">
          <NavBar title="业务员查询"></NavBar>
          <SearchBar placeholder="业务员手机号或名字" onChange={this.setQueryName} onSearch={this.nameSearch}></SearchBar>
        </div>
        <div className="reposReultList">
          {this.repositoryList()}
        </div>
        <BottomRefresh
          total={this.query.total}
          infactTotal={this.state.dataList.length}
          isLoading={this.state.loading}
          onSearch={this.pageSearch}></BottomRefresh>
      </div>
    )
  }
}
