import { Http } from "./core/http";

export class CommisionService {

  private static http: Http = new Http();

  static getTCList(page: number, rows: number, query: any) {
    return this.http.post('/order/getTCList', { page, rows, ...query});
  }

  static commisionStateSwitch(order_id: number, state: number, reason?: any) {
    return this.http.post('/order/stateSwitch', { order_id, state, reason});
  }
}
